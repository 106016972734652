export default {
	install: function (Vue) {
		Vue.directive("click-outside", {
			bind(el, binding, vnode) {
				console.log('BIND');
				el.clickOutsideEvent = (event) => {
					if (!(el === event.target || el.contains(event.target))) {
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener("click", el.clickOutsideEvent);
			},
			unbind(el) {
				console.log('UNBIND');
				document.body.removeEventListener("click", el.clickOutsideEvent);
			},
		});
	}
}
