<template>
	<div class="head__utils">
		<span class="trigger-search" @click="triggerSearch">
			<svg viewBox="0 0 16 17">
				<use xlink:href="#icons--search" width="100%" height="100%"></use>
			</svg>
		</span>
		<MobileNavBtn v-if="logged" />
		<LangSwitcher />
		<UserLink :logged="logged" />
	</div>
</template>
<script>
import MobileNavBtn from '@/components/layout/utils/MobileNavBtn';
import UserLink from '@/components/layout/utils/UserLink';
import LangSwitcher from '@/components/layout/utils/LangSwitcher';

export default {
	name: 'Utils',
	components: {
		MobileNavBtn,
		UserLink,
		LangSwitcher,
	},
	props: {
		logged: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		triggerSearch() {
			this.$root.$emit('trigger-search');
		}
	}
}
</script>
<style lang="scss">
.head__utils {
	display: flex;
	@extend %flexCenterStart;
	flex-direction: row-reverse;
	width: 100%;
	.trigger-search {
		display: flex;
		@extend %flexCenterCenter;
		@include size(var(--gutter),var(--gutter));
		order: 1;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $black;
			pointer-events: none;
		}
	}
	@media (max-width: $from-medium) {
		.userlink {
			display: none;
		}
	}
	@media (min-width: $to-medium) {
		.trigger-search { display: none; }
		.userlink {
			margin-left: var(--minigutter);
		}
	}
}
</style>