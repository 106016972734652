<template>
	<div class="breadcrumb">
		<a href="/" class="nextstep">
			<span class="breadcrumb__level" :class="{ 'is-clickable' : bc.length }">
				{{ $t('common.title') }}
			</span>
			<span class="breadcrumb__spacer" v-if="bc.length">
				<svg viewBox="0 0 22 16">
					<use xlink:href="#icons--back" width="100%" height="100%"></use>
				</svg>
			</span>
		</a>
		<a :href="`/#/${b.link}`" v-for="(b, bI) in bc" :key="bI" class="nextstep">
			<span class="breadcrumb__level" :class="{ 'is-clickable' : bI < bc.length - 1 }">{{ b.name }}</span>
			<span class="breadcrumb__spacer" v-if="bI < bc.length - 1">
				<svg viewBox="0 0 22 16">
					<use xlink:href="#icons--back" width="100%" height="100%"></use>
				</svg>
			</span>
		</a>
	</div>
</template>
<script>
export default {
	name: 'Breadcrumb',
	props: {
		data: {
			required: true,
		}
	},
	data() {
		return {
			bc: []
		}
	},
	mounted() {
		let filteredData = this.data;
		let links = [];

		Object.keys(this.$route.params).forEach(key => {
			if(this.$route.params[key]) {
				const tmp = filteredData.filter(d => {
					return this.$route.params[key] == d.id;
				})
				if(tmp && tmp.length) {
					filteredData = tmp[0].children;
					links.push(this.$route.params[key]);
					this.bc.push(tmp[0]);
					tmp[0].link = links.join('/')
				}
			}
		})
	}
}
</script>
<style lang="scss">
.breadcrumb {
	display: flex;
	@extend %flexCenterStart;
	flex-wrap: wrap;
	width: 100%;
	.nextstep {
		display: flex;
		@extend %flexCenterStart;
		width: max-content;
	}
	&__level {
		display: block;
		font-size: 1rem;
		pointer-events: none;
		color: $black;
		&.is-clickable {
			pointer-events: all;
			position: relative;
			cursor: pointer;
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				display: block;
				background: $black;
				@include size(100%,2px);
				transition: transform 0.36s;
				transform: scaleX(0);
				transform-origin: right;
			}
			&:hover::after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}
	}
	&__spacer {
		display: block;
		@include size(22px,16px);
		margin: 0 var(--minigutter);
		svg {
			display: block;
			@include size(100%,100%);
			fill: $black;
			transform: scaleX(-1);
		}
	}
	
}
</style>