<template>
	<div class="loader">
		<span class="loader__label">
			{{ label }}
		</span>
	</div>
</template>
<script>
export default {
	name: 'Loader',
	props: {
		label: {
			type: String,
			required: false,
			default: 'loading'
		}
	}
}
</script>
<style lang="scss" scoped>
.loader {
	display: flex;
	@extend %flexCenterCenter;
	padding: calc(var(--gutter) * 3) 0;
	&__label {
		display: block;
		color: $black;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			display: block;
			background: $black;
			@include size(100%,2px);
            animation: loading 2s $SineEaseOut infinite;
		}
	}
	@keyframes loading {
		0% {
			transform: scaleX(0);
			transform-origin: left center;
		}
		25% {
			transform: scaleX(1);
			transform-origin: left center;
		}
		26% {
			transform: scaleX(1);
			transform-origin: right center;
		}
		50% {
			transform: scaleX(0);
			transform-origin: right center;
		}
		75% {
			transform: scaleX(1);
			transform-origin: right center;
		}
		76% {
			transform: scaleX(1);
			transform-origin: left center;
		}
		100% {
			transform: scaleX(0);
			transform-origin: left center;
		}
	}
}
</style>