<template>
	<div id="accesso" class="accesso main-content" ref="accesso">
		<div class="content small-content">
			<h2 class="page--title">
				{{ $t('pages.psw.instructions.request.title') }}
			</h2>
			<div class="accesso__content">
				<div class="instructions">
					<div class="instructions__text">
						{{ $t('pages.psw.instructions.request.text') }}
					</div>
				</div>
				<div class="form__box">
					<form id="richiesta-accesso" class="form" name="">
						<fieldset class="form__fieldset">
							<span class="form__field field__input">
								<label for="firstname" class="field__label">
									{{ $t('request.firstname') }}
								</label>
								<input type="text" class="form__input" id="firstname" name="firstname"
									   v-model="form.first_name"/>
								<span v-if="isError('first_name_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.first_name_required') }}</span>
							</span>
							<span class="form__field field__input">
								<label for="lastname" class="field__label">
									{{ $t('request.lastname') }}
								</label>
								<input type="text" class="form__input" id="lastname" name="lastname"
									   v-model="form.last_name"/>
								<span v-if="isError('last_name_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.last_name_required') }}</span>
							</span>
							<span class="form__field field__input">
								<label for="city" class="field__label">
									{{ $t('request.city') }}
								</label>
								<input type="text" class="form__input" id="city" name="city" v-model="form.city"/>
								<span v-if="isError('city_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.city_required') }}</span>
							</span>
							<span class="form__field field__select">
								<label for="country" class="field__label">
									{{ $t('request.country') }}
								</label>
								<div id="country" class="fake-select" data-choice="">
									<span class="fake-placeholder"></span>
									<div class="dropdown">
										<span class="dropdown__close"></span>
										<label v-for="(s, sI) in states" :key="sI" :for="`${s}`"
											   class="dropdown__item_states dropdown__item checkradio-box">
											<input type="radio" name="country" class="checkradio-box--input"
												   :id="`${s}`" :value="s" v-model="form.country"/>
											<span class="fake-check"></span>
											<span class="checkradio-box--label">
												{{ s }}
											</span>
										</label>
									</div>
								</div>
								<span v-if="isError('country_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.country_required') }}</span>
							</span>
							<span class="form__field field__input">
								<label for="request_email" class="field__label">
									{{ $t('request.email') }}
								</label>
								<input type="email" class="form__input" id="request_email" name="request_email"
									   v-model="form.email"/>
								<span v-if="isError('email_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.email_required') }}</span>
								<span v-if="isError('email_not_valid')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.email_not_valid') }}</span>
							</span>
							<span class="form__field field__input">
								<label for="request_phone" class="field__label">
									{{ $t('request.phone') }}
								</label>
								<input type="email" class="form__input" id="request_phone" name="request_phone"
									   v-model="form.phone"/>
								<span v-if="isError('phone_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.phone_required') }}</span>
								<span v-if="isError('phone_not_valid')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.phone_not_valid') }}</span>
							</span>
							<span class="form__field field__input">
								<label for="company_name" class="field__label">
									{{ $t('request.company') }}
								</label>
								<input type="text" class="form__input" id="company_name" name="company_name"
									   v-model="form.company_name"/>
								<span v-if="isError('company_name_required')" class="request-error" aria-live="polite">{{
										$t('errors.users.company_name_required')
									}}</span>
							</span>
							<span class="form__field field__select">
								<label for="country" class="field__label">
									{{ $t('request.position') }}
								</label>
								<div id="positions" class="fake-select" data-choice="">
									<span class="fake-placeholder"></span>
									<div class="dropdown">
										<span class="dropdown__close"></span>
										<label v-for="(s, sI) in positions" :key="sI" :for="`${s}`"
											   class="dropdown__item_positions dropdown__item checkradio-box">
											<input type="radio" name="position" class="checkradio-box--input"
												   :id="`${s}`" :value="s" v-model="form.position"/>
											<span class="fake-check"></span>
											<span class="checkradio-box--label">
												{{ s }}
											</span>
										</label>
									</div>
								</div>
								<span v-if="isError('position_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.position_required') }}</span>
							</span>
							<span class="form__field field__select">
								<label for="country" class="field__label">
									{{ $t('request.sector') }}
								</label>
								<div id="sectors" class="fake-select" data-choice="">
									<span class="fake-placeholder"></span>
									<div class="dropdown">
										<span class="dropdown__close"></span>
										<label v-for="(s, sI) in sectors" :key="sI" :for="`${s}`"
											   class="dropdown__item_sectors dropdown__item checkradio-box">
											<input type="radio" name="sector" class="checkradio-box--input" :id="`${s}`"
												   :value="s" v-model="form.sector"/>
											<span class="fake-check"></span>
											<span class="checkradio-box--label">
												{{ s }}
											</span>
										</label>
									</div>
								</div>
								<span v-if="isError('sector_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.sector_required') }}</span>
							</span>
							<span class="form__field field__input">
								<label for="company_name" class="field__label">
									{{ $t('request.website') }}
								</label>
								<input type="text" class="form__input" id="website" name="website"
									   v-model="form.website"/>
								<span v-if="isError('website_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.website_required') }}</span>
							</span>

							<span class="form__field field__input">
								<label for="company_name" class="field__label">
									{{ $t('request.message') }}
								</label>
								<textarea type="text" class="form__input" id="message" name="message"
									   v-model="form.message"/>
								<span v-if="isError('message_required')" class="request-error"
									  aria-live="polite">{{ $t('errors.users.message_required') }}</span>
							</span>

						</fieldset>
						<fieldset class="form__fieldset">
							<label for="privacy-check" id="check-privacy-status" class="form__privacy checkradio-box">
								<input type="checkbox" name="privacy-check" class="checkradio-box--input"
									   id="privacy-check" v-model="privacycheck"/>
								<span class="fake-check"></span>
								<span class="checkradio-box--label">
									{{ $t('pages.psw.instructions.request.agree') }}
									<small class="privacy-policy__text"
										   v-html="$t('pages.psw.instructions.request.privacy')">

									</small>
								</span>
							</label>
						</fieldset>
						<div class="form__btns">
							<button type="button" id="submit-form" class="form__btn discover" @click="onSubmit"
									:disabled="!privacycheck">
								<span class="discover__circle"></span>
								<span class="discover__label">
									<template v-if="!is_loading">
										{{ $t('pages.psw.instructions.request.btn') }}
									</template>
									<template v-if="is_loading">
										{{ $t('pages.psw.instructions.request.btn_loading') }}
									</template>
								</span>
							</button>
						</div>
						<div v-if="isError('email_not_unique')" class="form__errors">
							{{ $t('request.errors.email_not_unique') }}
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'RequestAccess',
	data () {
		return {
			is_loading: false,
			states: [],
			sectors: [],
			positions: [],
			form: {
				first_name: '',
				last_name: '',
				email: '',
				phone: '',
				website: '',
				message: '',
				company_name: '',
				city: '',
				country: '',
				sector: '',
				position: ''
			},
			errors: [],
			privacycheck: false,
		}
	},
	watch: {
		privacycheck: {
			immediate: false,
			deep: true,
			handler (newval, oldval) {
				return newval;
			}
		}
	},
	methods: {
		onSubmit () {
			this.is_loading = true;
			this.service.users.request(this.form).then(res => {
				this.$root.$emit('request-access');
			}).catch(err => {
				err.messages.forEach(m => {
					this.errors.push(m);
				})
			}).finally(() => {
				this.is_loading = false;
			})
			// return;
		},
		isError (name) {
			return this.errors.indexOf(name) >= 0;
		},
		init () {
			this.states = [];
			this.sectors = [];
			this.positions = [];
			// countries
			this.service.users.getCountries().then((states) => {
				this.states = states;

				let s = this.$el.querySelector('#country');
				console.log(s);
				let placeholder = s.querySelector('.fake-placeholder');
				let dropdown = s.querySelector('.dropdown');

				placeholder.addEventListener('click', el => {
					let isOpen = dropdown.classList.contains('open');
					if (dropdown.querySelector('.dropdown.open')) {
						dropdown.querySelector('.dropdown.open').classList.remove('open');
					}
					setTimeout(() => {
						dropdown.classList.add('open');
						if (isOpen) {
							dropdown.classList.remove('open')
						}
					}, 50)
				})

				setTimeout(() => {
					let options = Array.from(document.querySelectorAll('.dropdown__item_states'));
					options.forEach(o => {
						o.addEventListener('click', el => {
							let label = el.target.value;
							placeholder.parentNode.setAttribute('data-choice', el.target.id);
							placeholder.classList.add('choosen')
							placeholder.innerHTML = label;
							dropdown.classList.remove('open');
						})
					})
				}, 500)
			});

			// sectors
			this.service.users.getSectors().then((sectors) => {
				this.sectors = sectors;

				let s = this.$el.querySelector('#sectors');
				console.log(s)
				let placeholder = s.querySelector('.fake-placeholder');
				let dropdown = s.querySelector('.dropdown');

				placeholder.addEventListener('click', el => {
					let isOpen = dropdown.classList.contains('open');
					if (document.querySelector('.dropdown.open')) {
						document.querySelector('.dropdown.open').classList.remove('open');
					}
					setTimeout(() => {
						dropdown.classList.add('open');
						if (isOpen) {
							dropdown.classList.remove('open')
						}
					}, 50)
				})

				setTimeout(() => {
					let options = Array.from(document.querySelectorAll('.dropdown__item_sectors'));
					options.forEach(o => {
						o.addEventListener('click', el => {
							let label = el.target.value;
							placeholder.parentNode.setAttribute('data-choice', el.target.id);
							placeholder.classList.add('choosen')
							placeholder.innerHTML = label;
							dropdown.classList.remove('open');
						})
					})
				}, 500)
			});

			// positions
			this.service.users.getPositions().then((positions) => {
				this.positions = positions;

				let s = this.$el.querySelector('#positions');
				console.log(s)
				let placeholder = s.querySelector('.fake-placeholder');
				let dropdown = s.querySelector('.dropdown');

				placeholder.addEventListener('click', el => {
					let isOpen = dropdown.classList.contains('open');
					if (document.querySelector('.dropdown.open')) {
						document.querySelector('.dropdown.open').classList.remove('open');
					}
					setTimeout(() => {
						dropdown.classList.add('open');
						if (isOpen) {
							dropdown.classList.remove('open')
						}
					}, 50)
				})

				setTimeout(() => {
					let options = Array.from(document.querySelectorAll('.dropdown__item_positions'));
					options.forEach(o => {
						o.addEventListener('click', el => {
							let label = el.target.value;
							placeholder.parentNode.setAttribute('data-choice', el.target.id);
							placeholder.classList.add('choosen')
							placeholder.innerHTML = label;
							dropdown.classList.remove('open');
						})
					})
				}, 500)
			});
		}
	},
	mounted () {
		this.init();
		window.scrollTo(0, 0);

		this.$root.$on('cambio-lingua', () => {
			this.init();
		})
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/recupera-password';

.instructions {
	display: block;
	width: 100%;
	margin: var(--gutter) auto;

	&__text {
		display: block;
		width: 100%;
		text-align: left;
		margin: 0;
	}

	@media (min-width: $to-medium) {
		width: 75%;
		&__text {
			width: 60%;
		}
	}
}

.form {
	margin-top: calc(var(--gutter) * 2);

	.dropdown__item {
		& * {
			pointer-events: none;
		}
	}

	&__fieldset,
	&__btns {
		&.centered {
			.form__field,
			.form__btn {
				margin: 0 auto;
			}

			input {
				&.psw-error {
					border-color: $red;
				}
			}
		}
	}

	&__btn {
		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}

	&__errors {
		display: block;
		width: 100%;
		padding: var(--gutter);
		background: rgba($red, 0.2);
		color: $black;
		text-align: center;
		text-transform: uppercase;
	}

	.input-box {
		display: block;
		width: 100%;
		position: relative;

		.show-psw {
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			@include size(var(--miniicon), var(--miniicon));
			transform: translateY(-50%);
			cursor: pointer;

			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%, 100%);
				pointer-events: none;

				svg {
					display: block;
					@include size(100%, 100%);
					fill: $black;
				}
			}

			&__hide {
				display: none;
			}

			&.show {
				.show-psw__show {
					display: none;
				}

				.show-psw__hide {
					display: block;
				}
			}
		}
	}

	.error {
		display: block;
		width: 100%;
		text-align: center;
		color: $red;
	}
}

#accesso * {
	// text-transform: lowercase;
}

.request-error {
	color: $red;
}
</style>
