<template>
    <footer id="foot" class="foot">
        <div class="content">
            <div class="foot__macro foot__box">
                <a :href="`https://www.pedrali.com/${lang}/hospitality`" class="foot__macro--item foot__link">
                    hospitality
                </a>
                <a :href="`https://www.pedrali.com/${lang}/outdoor`" class="foot__macro--item foot__link">
                    outdoor
                </a>
                <a :href="`https://www.pedrali.com/${lang}/residential`" class="foot__macro--item foot__link">
                    residential
                </a>
                <a :href="`https://www.pedrali.com/${lang}/workspaces-corporate`" class="foot__macro--item foot__link">
                    workspaces &amp; corporate
                </a>
            </div>
            <div class="foot__utilities foot__box">
				<template v-if="$i18n.locale == 'it'">
					<a href="https://www.pedrali.com/it-it/richiedi-informazioni" class="foot__utilities--item foot__link" aria-label="Link a pagina Dove siamo">
						{{ $t('footer.inforequest') }}
					</a>
					<a href="https://www.pedrali.com/it-it/dove-siamo" class="foot__utilities--item foot__link" aria-label="Link a pagina a Richiedi Informazioni">
					{{ $t('footer.wherearewe') }}
					</a>
					<a href="https://www.pedrali.com/it-it/lavora-con-noi" class="foot__utilities--item foot__link" aria-label="Link a pagina Lavora con noi">
						{{ $t('footer.workwithus') }}
					</a>
					<a href="https://www.pedrali.com/it-it/privacy-policy" class="foot__utilities--item foot__link" aria-label="Link a pagina Privacy Policy">
						{{ $t('footer.privacy') }}
					</a>
				</template>
				<template v-if="$i18n.locale == 'en'">
					<a href="https://www.pedrali.com/en-us/information-request" class="foot__utilities--item foot__link" aria-label="Link a pagina Dove siamo">
						{{ $t('footer.inforequest') }}
					</a>
					<a href="https://www.pedrali.com/en-us/how-to-reach-us" class="foot__utilities--item foot__link" aria-label="Link a pagina a Richiedi Informazioni">
					{{ $t('footer.wherearewe') }}
					</a>
					<!-- <a href="https://www.pedrali.com/it-it/lavora-con-noi" class="foot__utilities--item foot__link" aria-label="Link a pagina Lavora con noi">
						{{ $t('footer.workwithus') }}
					</a> -->
					<a href="https://www.pedrali.com/en-us/privacy-policy" class="foot__utilities--item foot__link" aria-label="Link a pagina Privacy Policy">
						{{ $t('footer.privacy') }}
					</a>
				</template>
            </div>
            <div class="foot__copy foot__box">
                <span class="foot__copy--logo">
					<!-- <img src="@/assets/images/logo60.png" alt="" /> -->
					<svg viewBox="0 0 160 17">
						<use xlink:href="#logo--main" width="100%" height="100%"></use>
					</svg>
                </span>
                <small class="foot__copy--legal">
                    <!-- &copy; <span class="foot__copy--year"></span> Pedrali S.p.A. - P.IVA/VAT code 02001160981 -->
					&copy; {{ year }} pedrali s.p.a. | {{ vat }} 02001160981 | p. +39 035 83588{{ $i18n.locale == 'en' ? '40' : ' ' }}| f. +39 035 8358888 | <a href="mailto:info@pedrali.it" target="_blank" style="color:#000;">info@pedrali.it</a> | <router-link :to="{ name: 'Disclaimer' }" style="color: #000;">disclaimer</router-link>
                </small>
            </div>
            <div class="foot__social foot__box">
                <a href="https://www.instagram.com/pedralispa/" target="_blank" class="foot__social--item" aria-label="Link social">
                    <svg viewBox="0 0 30 30">
                        <use xlink:href="#social--instagram" width="100%" height="100%"></use>
                    </svg>
                </a>
                <a href="https://www.linkedin.com/company/pedrali-spa" target="_blank" class="foot__social--item" aria-label="Link social">
                    <svg viewBox="0 0 30 30">
                        <use xlink:href="#social--linkedin" width="100%" height="100%"></use>
                    </svg>
                </a>
                <a href="https://www.pinterest.it/pedralispa/" target="_blank" class="foot__social--item" aria-label="Link social">
                    <svg viewBox="0 0 30 30">
                        <use xlink:href="#social--pinterest" width="100%" height="100%"></use>
                    </svg>
                </a>
                <a href="https://twitter.com/Pedrali" target="_blank" class="foot__social--item" aria-label="Link social">
                    <svg viewBox="0 0 30 30">
                        <use xlink:href="#social--twitter" width="100%" height="100%"></use>
                    </svg>
                </a>
                <a href="https://www.youtube.com/user/pedralispa" target="_blank" class="foot__social--item" aria-label="Link social">
                    <svg viewBox="0 0 30 30">
                        <use xlink:href="#social--youtube" width="100%" height="100%"></use>
                    </svg>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Foot',
	data() {
		return {
			year: '2022',
			lang: 'it-it',
			vat: 'p.iva',
		}
	},
	methods: {
		init() {
			this.year = new Date().getFullYear();

			if(this.$i18n.locale == 'it') {
				this.lang = 'it-it';
				this.vat = 'p.iva'
			}
			if(this.$i18n.locale == 'en') {
				this.lang = 'en-us';
				this.vat = 'vat code'
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>

<style lang="css" scoped>
</style>
