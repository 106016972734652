<template>
	<button id="navbtn" class="navbtn" @click="handleClick">
		<span class="bar"></span>
		<span class="bar"></span>
		<span class="bar"></span>
	</button>
</template>
<script>
export default {
	name: 'MobileNavBtn',
	methods: {
		handleClick() {
			this.$root.$emit('show-login');
			// if(this.$el.classList.contains('close')) {
			// 	this.$root.$emit('close-menu', '');
			// 	this.$el.classList.remove('close');
			// } else {
			// 	this.$root.$emit('open-menu', '');
			// 	this.$el.classList.add('close');
			// }
		}
	}
}
</script>
<style lang="scss">
.navbtn {
	display: block;
	@include size(var(--nav-icons),var(--nav-icons));
	position: relative;
	border: 0;
	margin-right: var(--minigutter);
	background: transparent;
	z-index: 100;
	.bar {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		background: $black;
		@include size(32px,1px);
		transform: translate(-50%,-50%);
		transition: 180ms $SineEaseOut;
		pointer-events: none;
		&:nth-of-type(1) {
			margin-top: -6px;
		}
		&:nth-of-type(3) {
			margin-top: 6px;
		}
	}
	&.close {
		.bar {
			&:nth-of-type(1) {
				margin-top: 0;
				transform: translate(-50%,-50%) rotate(45deg);
			}
			&:nth-of-type(2) {
				transform: translate(-50%,-50%) scaleX(0);
			}
			&:nth-of-type(3) {
				margin-top: 0;
				transform: translate(-50%,-50%) rotate(-45deg);
			}
		}
	}
	@media (min-width: $to-medium) {
		display: none;
	}
}
</style>