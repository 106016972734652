<template>
	<div id="app" :class="{ 'lowpadding': !showLayout }">
		<Icons />
		<template v-if="showLayout">
			<Head :logged="isLogged" />
		</template>
		<router-view/>
		<template v-if="showLayout">
			<Foot />
		</template>
		<PreviewOverlay />
		<LoginForm />
		<OverlayForms />
		<OverlaySession />
	</div>
</template>
<script>
import Icons from '@/components/layout/Icons';
import Head from '@/components/layout/Head';
import Foot from '@/components/layout/Foot';
import PreviewOverlay from '@/components/common/PreviewOverlay';
import LoginForm from '@/components/LoginForm';
import OverlayForms from '@/components/common/OverlayForms';
import OverlaySession from '@/components/common/OverlaySession';

export default {
	name: 'App',
	components: {
		Icons,
		Head,
		Foot,
		PreviewOverlay,
		LoginForm,
		OverlayForms,
		OverlaySession
	},
	data() {
		return {
			isLogged: false,
			showLayout: true,
			userdata: null,
		}
	},
	methods: {
		init() {
			
			localStorage.setItem('locale', this.$i18n.locale);
			if(this.$route && this.$route.name === 'ShowroomVisit') {
				this.showLayout = false;
			} else {
				this.showLayout = true;
			}
			if(!(localStorage.getItem('token') && localStorage.getItem('token') != '')) {
				this.isLogged = false;
			} else {
				this.isLogged = true;
				this.$root.$emit('get-user');
			}
		}
	},
	mounted() {
		window.addEventListener(`contextmenu`, (e) => {
			e.preventDefault();
		});
		this.init();
	}
}
</script>
<style lang="scss">
.lowpadding {
	.main-content {
		padding: var(--gutter) 0;
	}
}
</style>
