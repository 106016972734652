<template>
	<div class="search" v-click-outside="closeSearch">
		<span class="search__arrow">
			<span class="search__arrow__icon" @click="closeDrawer">
				<svg viewBox="0 0 22 15">
					<use xlink:href="#search--arrow" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="search__arrow__label">
				{{ $t('search.title') }}
			</span>
		</span>
		<input type="text" class="search__input" name="search-field" id="search-field" :placeholder="$t('common.cerca')" v-model="searchTerm" @input="initAutocomplete" />
		<div class="search__suggestions" :class="`${((searchTerm.length < 3) && (searchRes.length == 0)) ? 'hidden' : ''}`">
			<template v-if="searchTerm.length > 2 && searchRes.length > 0">
				<span v-for="r in searchRes" :key="r.id" class="search__suggestions__item">
					<SearchPreview :data="r" />
				</span>
			</template>
			<template v-if="searchTerm.length > 2 && searchRes.length == 0">
				<span class="search__suggestions__nores">
					{{ $t('search.nores') }}
				</span>
			</template>
		</div>
	</div>
</template>
<script>
import SearchPreview from '@/components/common/SearchPreview';

export default {
	name: 'Search',
	components: {
		SearchPreview,
	},
	data() {
		return {
			docs: null,
			searchTerm: '',
			sugg: [],
			searchRes: [],
			searchAuto: [],
			showSearch: false,
		}
	},
	methods: {
		closeDrawer() {
			document.querySelector('.search').classList.remove('show')
		},
		closeSearch() {
			this.$el.querySelector('.search__suggestions').classList.add('hidden')
		},
		initAutocomplete() {
			if(this.searchTerm.length > 2) {
				this.$el.querySelector('.search__suggestions').classList.remove('hidden')
				this.startSearch();
			} else {
				this.searchRes = [];
			}
		},
		startSearch() {
			this.searchRes = [];
			let docs = this.$store.state.docs;
			let that = this;

			function searchDocs(list) {
				list = list || docs;

				list.forEach(l => {
					if(!l.folder && l.name.toLowerCase().includes(that.searchTerm.toLowerCase())) {
						that.searchRes.push(l)
					}
					if(l.folder && l.children.length) {
						searchDocs(l.children);
					}
				})

			}
			searchDocs();
		},
		init() {
			// let search = document.querySelector('.search');

			// document.addEventListener('click', (event) => {
			// 	console.log(event.target, event.target.contains(search));
			// 	if (event.target.contains(search) && event.target !== search) {
			// 		this.closeSearch();
			// 	} else if(event.target.contains(search) && event.target == search) {
			// 		this.initAutocomplete();
			// 	}
			// })

			this.$root.$on('trigger-search', () => {
				console.log('OPEN');
				document.querySelector('.search').classList.add('show');
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.search {
	top: 0;
	display: block;
	@include size(100%,100%);
	margin: 0 auto;
	z-index: 1000;
	&__arrow {
		display: flex;
		@extend %flexCenterStart;
		width: 100%;
		margin-bottom: var(--gutter);
		&__icon {
			display: flex;
			@extend %flexCenterCenter;
			@include size(var(--gutter),var(--gutter));
			svg {
				display: block;
				@include size(75%,75%);
				fill: $black;
			}
		}
		&__label {
			display: block;
			width: calc(100% - var(--gutter));
			padding-left: var(--minigutter);
		}
	}
	&__input {
		display: block;
		@include size(100%,var(--input));
		border: 1px solid rgba($black, 0.4);
		border-radius: 6px;
		position: relative;
		padding: 0 var(--gutter) 0 var(--minigutter);
		z-index: 1;
	}
	&__icon {
		position: absolute;
		top: 50%;
		right: var(--minigutter);
		@extend %flexCenterCenter;
		@include size(var(--minigutter),var(--minigutter));
		z-index: 2;
		transform: translateY(-50%);
		cursor: pointer;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $black;
		}
	}
	&__suggestions {
		position: absolute;
		left: 0;
		background: $white;
		width: 100%;
		max-height: 80vh;
		overflow-y: auto;
		z-index: 1000;
		border-left: 1px solid rgba($black, 0.4);
		border-right: 1px solid rgba($black, 0.4);
		border-bottom: 1px solid rgba($black, 0.4);
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		&.hidden {
			display: none;
		}
		&__nores {
			display: flex;
			@extend %flexCenterStart;
			@include size(100%,var(--input));
			padding: 6px var(--minigutter);
		}
		&__item {
			display: block;
			@include size(100%,var(--input));
			border-bottom: 1px solid rgba($black, 0.2);
			.file {
				display: flex;
				@extend %flexCenterStart;
				@include size(100%,100%);
				margin-bottom: 0;
				padding: 6px;
				.fake-checkbox { display: none !important; }
				&--icon {
					@include size(calc(var(--input) - 12px),calc(var(--input) - 12px));
					margin-bottom: 0;
					svg {
						@include size(75%,75%);
					}
				}
				&--label {
					display: flex;
					@extend %flexStartCenter;
					flex-direction: column;
					@include size(calc(100% - (var(--input) - 12px)), 100%);
					text-align: left;
					padding-left: var(--minigutter);
					small {
						text-align: left;
					}
				}
			}
		}
	}
	@media (max-width: $from-medium) {
		position: fixed;
		left: 0;
		@include size(100%,100vh);
		background: $white;
		overflow: hidden;
		padding: var(--minigutter);
		transform: translateX(110%);
		transition: 250ms linear;
		pointer-events: none;
		&__suggestions {
			top: calc(var(--minigutter) + var(--input) + var(--gutter) + 29px);
			left: var(--minigutter);
			width: calc(100% - var(--gutter));
		}
		&.show {
			pointer-events: all;
			transform: translateX(0);
		}
	}
	@media (min-width: $to-medium) {
		position: absolute;
		left: 50%;
		max-width: 600px;
		transform: translateX(-50%);
		padding: 7px 0;
		&__arrow {
			display: none;
		}
		&__suggestions {
			top: calc(100% - 11px);
		}
	}
}
</style>