<template>
	<div id="overlayforms" class="overlayform" :class="show ? 'show' : ''">
		<div class="overlayform__box">
			<span class="overlayform__box__close" @click="closeOverlay"></span>
			<template v-if="isrecover">
				<span class="overlayform__box__title">
					{{ $t('recover.title') }}
				</span>
				<form id="recover-psw" class="form" name="">
					<fieldset class="form__fieldset">
						<span class="form__field field__input">
							<input type="email" class="form__input" id="request_email" name="request_email" :placeholder="$t('recover.email')" v-model="form.email" />
							{{ errors['email_not_valid'] }}
							<span v-if="isError('email_required')" class="recover-error" aria-live="polite">{{ $t('errors.psw.email-required') }}</span>
							<span v-if="isError('email_not_valid')" class="recover-error" aria-live="polite">{{ $t('errors.psw.email_not_valid') }}</span>
						</span>
					</fieldset>
					<div class="form__btns">
						<button type="button" id="submit-form" class="form__btn discover" @click="onSubmit">
							<span class="discover__circle"></span>
							<span class="discover__label">{{ $t('recover.submit') }}</span>
						</button>
					</div>
				</form>
			</template>
			<template v-if="confirmrecover">
				<span class="overlayform__box__text" v-html="$t('recover.confirm')"></span>
			</template>
			<template v-if="confirmrequest">
				<span class="overlayform__box__title">{{ $t('request.title') }}</span>
				<span class="overlayform__box__text" v-html="$t('request.confirm')"></span>
			</template>
			<template v-if="confirmvisit">
				<span class="overlayform__box__title">{{ $t('showroom.message.title') }}</span>
				<span class="overlayform__box__text" v-html="$t('showroom.message.text')"></span>
			</template>
			<template v-if="nofiles">
				<span class="overlayform__box__text" v-html="$t('loading.nofiles')"></span>
			</template>
		</div>
	</div>
</template>
<script>
export default {
	name: 'OverlayForms',
	data() {
		return {
			isrecover: false,
			confirmrecover: false,
			confirmrequest: false,
			confirmvisit: false,
			nofiles: false,
			show: false,
			submitted: false,
			error: '',
			loading: false,
			form: {
				email: ''
			},
			errors: [],
		}
	},
	components: {
	},
	methods: {
		closeOverlay() {
			this.$el.classList.remove('show')
			this.show = false;

			setTimeout(() => {
				if(!this.confirmvisit) {
					this.isrecover = false;
					this.confirmrecover = false;
					this.confirmrequest = false;
					this.confirmvisit = false;
					this.nofiles = false;
					window.location.href = '/';
				} else {
					this.isrecover = false;
					this.confirmrecover = false;
					this.confirmrequest = false;
					this.confirmvisit = false;
					this.nofiles = false;
				}
			}, 10)
		},
		onSubmit() {
			this.errors = [];
			this.service.users.recover({ email: this.form.email }).then(res => {
				this.isrecover = false;
				this.confirmrecover = true;
			}).catch(err => {
				err.messages.forEach(m => {
					this.errors.push(m);
				})
				console.log('ERRORS', this.errors);
			});
		},
		isError(name) {
			return this.errors.indexOf(name) >= 0;
		},
		init() {
			this.$root.$on('recover-password', () => {
				this.show = true;
				this.isrecover = true;
				this.confirmrecover = false;
				this.confirmrequest = false;
				this.confirmvisit = false;
				this.nofiles = false;
			})
			this.$root.$on('request-access', () => {
				this.show = true;
				this.isrecover = false;
				this.confirmrecover = false;
				this.confirmrequest = true;
				this.confirmvisit = false;
				this.nofiles = false;
			})
			this.$root.$on('request-visit', () => {
				this.show = true;
				this.isrecover = false;
				this.confirmrecover = false;
				this.confirmrequest = false;
				this.confirmvisit = true;
				this.nofiles = false;
			})
			this.$root.$on('no-files', () => {
				this.show = true;
				this.isrecover = false;
				this.confirmrecover = false;
				this.confirmrequest = false;
				this.nofiles = true;
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.overlayform {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	@extend %flexCenterCenter;
	background: rgba($black, 0.8);
	@include size(100%,100vh);
	z-index: 100001;
	opacity: 0;
	pointer-events: none;
	&__box {
		display: block;
		background: $white;
		padding: var(--gutter);
		width: 90vw;
		max-width: 350px;
		opacity: 0;
		transform: translateY(100px);
		&__title {
			display: block;
			width: 100%;
			font-weight: 700;
			font-size: 2.063rem;
			line-height: 1;
			margin: var(--gutter) 0;
		}
		&__close {
			float: right;
			display: block;
			@include size(20px,20px);
			position: relative;
			@extend %arrowClose;
			cursor: pointer;
			&:before,
			&:after {
				background: $black;
				@include size(80%,1px);
			}
		}
		.form {
			width: 100% !important;
			&__fieldset {
				margin-bottom: 0;
			}
			&__field {
				width: 100% !important;
			}
			&__btns {
				margin-top: 0;
			}
			&__btn {
				margin-top: var(--gutter);
			}
			.recover-error {
				display: block;
				width: 100%;
				text-align: center;
				color: $red;
			}
		}
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.overlayform__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
}
</style>