<template>
	<a href="javascript:void(0);" class="userlink" :class="{ 'disabled' : !logged }" aria-label="Login link" @click="userAction">
		<span class="userlink__icon">
			<svg viewBox="0 0 14 18">
				<use xlink:href="#icons--user" width="100%" height="100%"></use>
			</svg>
		</span>
		<span class="userlink__label">
			Profilo
		</span>
	</a>
</template>
<script>
export default {
	name: 'UserLink',
	props: {
		logged: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		userAction() {
			if(this.logged) {
				this.$root.$emit('show-login');
			}
		}
	}
}
</script>
<style lang="scss">
.userlink {
	display: flex;
	@extend %flexCenterCenter;
	flex-direction: column;
	&.disabled {
		opacity: 0;
		pointer-events: none;
	}
	&__icon {
		display: flex;
		@extend %flexCenterCenter;
		@include size(var(--icon),var(--icon));
		margin: 0 auto;
		svg {
			display: block;
			@include size(100%,100%);
			fill: $black;
		}
	}
	&__label {
		display: block;
		width: 100%;
		font-family: $bold;
		font-size: 1.3rem;
		color: $black;
		text-align: center;
		text-transform: uppercase;
		padding-top: var(--minigutter);
	}
	@media (min-width: $to-medium) {
		padding: 0;
		@include size(var(--miniicon),var(--miniicon));
		&__icon {
			@include size(100%,100%);
		}
		&__label {
			display: none;
		}
	}
}
</style>