<template>
	<div class="langswitcher">
		<a href="javascript:void(0);" class="langswitcher__lang" @click="langSwitch('it')" :class="{ 'current': $i18n.locale === 'it' }">
			It
		</a>
		<a href="javascript:void(0);" class="langswitcher__lang" @click="langSwitch('en')" :class="{ 'current': $i18n.locale === 'en' }">
			En
		</a>
	</div>
</template>
<script>
export default {
	name: 'LangSwitcher',
	methods: {
		langSwitch(lang) {
			this.$i18n.locale = lang;
			localStorage.setItem('locale', lang);
			this.$root.$emit('cambio-lingua');
		}
	},
	mounted() {
		console.log(this.$i18n.locale);
	}
}
</script>
<style lang="scss">
.langswitcher {
	display: flex;
	@extend %flexCenterCenter;
	width: 100%;
	text-align: center;
	margin: var(--gutter) 0;
	&__lang {
		display: block;
		font-family: $bold;
		font-size: 1rem;
		color: $black;
		margin: 0 var(--minigutter);
		text-transform: uppercase;
		&.current {
			text-decoration: underline;
		}
	}
	@media (max-width: $from-medium) {
		max-width: 110px;
	}
	@media (min-width: $to-medium) {
		width: max-content;
		&__lang {
			font-size: 1rem;
			margin: 0 calc(var(--minigutter) / 2);
		}
	}
}
</style>