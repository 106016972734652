<template>
	<header id="head" class="head">
		<div class="content">
			<a href="/" class="head__logo" aria-label="Logo Pedrali">
				<!-- <img src="@/assets/images/logo60.png" alt="" /> -->
				<svg viewBox="0 0 160 17">
					<use xlink:href="#logo--main" width="100%" height="100%"></use>
				</svg>
			</a>
			<Search v-if="logged" />
			<div class="head__stuff">
				<Utils :logged="logged" />
			</div>
		</div>
	</header>
</template>

<script>
import Utils from '@/components/layout/Utils';
import Search from '@/components/layout/utils/Search';

export default {
	name: 'Head',
	components: {
		Utils,
		Search
	},
	props: {
		logged: {
			type: Boolean,
			required: true
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/scss/layout/head';
</style>
