<template>
	<div id="profilo" class="profilo main-content" ref="profilo">
		<div class="content small-content">
			<h1 class="page--title">
				{{ $t('recover.title') }}
			</h1>
			<div class="profilo__content">
				<template v-if="!pswchanged">
					<div class="instructions">
						<span class="instructions__text">
							{{ $t('pages.psw.instructions.request.text') }}
						</span>
					</div>
					<div class="form__box">
						<form id="richiesta-informazioni" class="form" name="">
							<fieldset class="form__fieldset centered">
								<span class="form__field field__input">
									<label for="reset-password" class="field__label">
										{{ $t('pages.psw.insert') }}
									</label>
									<span class="input-box">
										<input type="password" class="form__input" id="reset-password" name="reset-password" v-model="psw" />
										<span class="show-psw" @click="togglePswVisibility">
											<span class="show-psw__show">
												<svg viewBox="0 0 52 26">
													<use xlink:href="#icons--hidepsw" width="100%" height="100%"></use>
												</svg>
											</span>
											<span class="show-psw__hide">
												<svg viewBox="0 0 52 30">
													<use xlink:href="#icons--showpsw" width="100%" height="100%"></use>
												</svg>
											</span>
										</span>
									</span>
								</span>
							</fieldset>
							<fieldset class="form__fieldset centered">
								<span class="form__field field__input">
									<label for="reset-conferma-password" class="field__label">
										{{ $t('pages.psw.confirm') }}
									</label>
									<span class="input-box">
										<input type="password" class="form__input" id="reset-conferma-password" name="reset-conferma-password" v-model="pswVerify" />
										<span class="show-psw" @click="togglePswVisibility">
											<span class="show-psw__show">
												<svg viewBox="0 0 52 26">
													<use xlink:href="#icons--hidepsw" width="100%" height="100%"></use>
												</svg>
											</span>
											<span class="show-psw__hide">
												<svg viewBox="0 0 52 30">
													<use xlink:href="#icons--showpsw" width="100%" height="100%"></use>
												</svg>
											</span>
										</span>
									</span>
								</span>
							</fieldset>
							<span v-if="nomatch" class="error" aria-live="polite">
								{{ $t('pages.psw.nomatch') }}
							</span>
							<div class="form__btns centered">
								<button type="button" id="submit-form" class="form__btn discover" @click="checkPassword">
									<span class="discover__circle"></span>
									<span class="discover__label">{{ $t('pages.psw.confirm') }}</span>
								</button>
							</div>
						</form>
					</div>
				</template>
				<template v-else>
					{{ $t('pages.psw.instructions.reset.confirm') }}
				</template>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'PasswordReset',
	data() {
		return {
			psw: null,
			pswVerify: null,
			nomatch: false,
			pswchanged: false,
		}
	}/*,
	watch: {
		pswVerify: {
			deep: true,
			immediate: false,
			handler(newVal, oldVal) {
				this.checkPassword();
			}
		}
	}*/,
	methods: {
		togglePswVisibility(item) {
			let input = item.target.parentNode.querySelector('input');

			item.target.classList.contains('show') ? item.target.classList.remove('show') : item.target.classList.add('show')

			if(input.type == 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}

		},
		checkPassword() {
			if(this.psw !== this.pswVerify) {
				if(this.pswVerify.length > 3) {
					this.$el.querySelector('#reset-conferma-password').classList.add('psw-error');
					this.nomatch = true;
					return;
				}
			} else {
				this.$el.querySelector('#reset-conferma-password').classList.remove('psw-error');
				this.nomatch = false;
				this.submitForm(this.psw);
			}
		},
		submitForm(newpsw) {
			let newdata = { token: this.$route.query.token, password: newpsw };
			this.service.users.restore(newdata).then(res => {
				console.log(res);
				// this.pswchanged = true;
				this.pswchanged = true;
				this.$forceUpdate();
			}).catch(err => {
				console.log(err);
				this.pswchanged = true;
				this.$forceUpdate();
			});
		}
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/recupera-password';
.instructions {
	display: block;
	width: 100%;
	margin: var(--gutter) auto;
	&__text {
		display: block;
		width: 100%;
		margin: 0 auto;
	}
	@media (min-width: $to-medium) {
		width: 75%;
		&__text {
			width: 48%;
		}
	}
}
.form {
	margin-top: calc(var(--gutter) * 2);
	&__fieldset,
	&__btns {
		&.centered {
			.form__field,
			.form__btn {
				margin: 0 auto;
			}
			input {
				&.psw-error {
					border-color: $red;
				}
			}
		}
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			@include size(var(--miniicon),var(--miniicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	.error {
		display: block;
		width: 100%;
		text-align: center;
		color: $red;
	}
}
</style>
