<template>
    <label :for="data.id" class="file repo--item__preview" @dblclick="downloadFile" @click="handleClick">
        <input type="checkbox" class="file--input item--input" :name="data.id" :id="data.id" />
        <span class="fake-checkbox">
            <svg viewBox="0 0 34 31">
                <use xlink:href="#icons--check" width="100%" height="100%"></use>
            </svg>
        </span>
        <span class="file--icon o-ratio u-1:1">
			<template v-if="isImage">
            	<img v-if="data.thumbnail" :src="data.thumbnail" alt="" />
			</template>
			<template v-else>
				<span v-if="fileIcon && fileIcon != ''" class="file--icon__svg">
					<svg viewBox="0 0 50 50">
						<use :xlink:href="`#type--${fileIcon}`" width="100%" height="100%"></use>
					</svg>
				</span>
			</template>
        </span>
        <span class="file--label">
            {{ data.name }}
			<small>
				{{ `${getType(data.mime_type)} - ${getSize(data.size)}` }}
			</small>
        </span>
    </label>
</template>

<script>
export default {
    name: 'FilePreview',
    props: {
        data: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            windowWidth: 0,
            interval: false,
            timer: 0,
			fileType: '',
			fileSize: '',
			isImage: false,
			fileIcon: '',
        }
    },
    watch: {
        windowWidth(newWidth, oldWidth) {
            return newWidth;
        },
		data(newVal, oldVal) {
			return newVal;
		}
    },
    methods: {
		bytesToSize(bytes) {
			return bytes / (Math.pow(1024, 2));
		},
		getType(type) {
			let filetype = type.split('/');
			let previewtypes = ['jpeg', 'jpg', 'pdf', 'mp4'];

			if(filetype[1] == 'plain') {
				filetype[1] = 'text';
			}
			return filetype[1];

		},
		getSize(size) {
			let fileSize = this.bytesToSize(size).toFixed(3);
			return `${fileSize} MB`
		},
		getIcon(type) {
			this.data.previewicon = '';

			let filetype = type.split('/');
			let previewtypes = ['jpeg', 'jpg', 'pdf', 'mp4'];

			this.fileIcon = filetype[1].replaceAll('.','-');

			if(filetype[1] == 'plain') {
				this.fileIcon = 'text';
			}
			if(previewtypes.includes(filetype[1])) {
				this.isImage = true;
			}
		},
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        downloadFile() {
			this.service.documents.download(this.data.id).then(res => {
				this.$root.$emit('open-preview', { res: res, data: this.data });
			});
        },
        handleClick(item) {
			item.target.classList.contains('selected') ? item.target.classList.remove('selected') :  item.target.classList.add('selected');
            // if(this.windowWidth > 1200) {
            // } else {
			// 	this.service.documents.download(this.data.id).then(res => {
			// 		this.$root.$emit('open-preview', { res: res, data: this.data, ismobile: true });
			// 	});
            // }
        },
        selectItem(item) {
            if(!this.interval) {
                this.interval = setInterval(() => {
                    this.timer++;
                    if(this.timer > 1) {
                        item.target.classList.contains('selected') ? item.target.classList.remove('selected') :  item.target.classList.add('selected');
                        clearInterval(this.interval);
                        this.interval = false;
                    }
                }, 300);
            }
        },
        cancelSelection(item) {
            this.timer = 0;
            clearInterval(this.interval);
            this.interval = false;
            if(this.timer > 1) {
                item.target.classList.contains('selected') ? item.target.classList.remove('selected') :  item.target.classList.add('selected');
            }
        }
    },
    mounted() {
        this.windowWidth = window.innerWidth;
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.onResize();
            });
        });

		if(this.data) {
			this.getIcon(this.data.mime_type);
		}
    }
}
</script>

<style lang="scss">
.file {
    display: block;
    width: calc(100% - var(--minigutter));
    margin: 0 calc(var(--minigutter) / 2) var(--minigutter);
    padding: var(--minigutter);
    position: relative;
    cursor: pointer;
    &--input { display: none; }
    &--input, .fake-checkbox,
    &--icon, &--label {
        pointer-events: none;
    }
    .fake-checkbox {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        @extend %flexCenterCenter;
        @include size(20px,20px);
        border-radius: 100%;
        border: 1px solid $black;
        svg {
            display: block;
            @include size(60%,60%);
            fill: $black;
            opacity: 0;
        }
    }
    &--icon {
        display: block;
        width: 75%;
        margin: 0 auto var(--minigutter);
        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            @include size(100%,100%);
            object-fit: cover;
        }
		&__svg {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			@include size(100%,100%);
			border: 1px solid rgba($black, 0.2);
			border-radius: 5px;
			svg {
				display: block;
				@include size(var(--gutter),var(--gutter));
				fill: $timberwolf;
			}
		}
    }
    &--label {
        display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
        width: 100%;
        font-size: 0.83rem;
		line-height: 1.2;
        white-space: normal;
        text-align: center;
        hyphens: auto;
		overflow-wrap: anywhere;
		small {
			display: block;
			width: 100%;
			text-align: center;
			margin-top: 5px;
			opacity: 0.6;
		}
    }
    &.selected {
        background: $certbg;
        .fake-checkbox {
            background: $green;
            border: 0;
            svg {
                opacity: 1;
                fill: $white;
            }
        }
    }
    @media (hover: hover) {
        &:hover {
            background: $certbg;
            .fake-checkbox {
                svg { opacity: 1; }
            }
        }
    }
    @media (min-width: $to-small) {
        // width: calc(33% - var(--minigutter));
        .fake-checkbox {
            @include size(var(--miniicon),var(--miniicon));
        }
    }
    @media (min-width: $to-medium) {
        // width: calc(25% - var(--minigutter));
		&--icon {
			&__svg {
				svg {
					@include size(60px,60px);
				}
			}
		}
    }
}
</style>
