<template>
	<div class="previewoverlay">
		<div class="previewoverlay__head">
			<a href="javascript:void(0);" class="previewoverlay__head__download" @click="downloadFile()">
				<span class="previewoverlay__head__download__icon">
					<svg viewBox="0 0 14 22">
						<use xlink:href="#icons--download" width="100%" height="100%"></use>
					</svg>
				</span>
				<span class="previewoverlay__head__download__label">
					{{ $t('pages.areariservata.scarica') }}
				</span>
			</a>
			<a class="previewoverlay__head__close" href="javascript:history.back(-1)" >
			</a>
		</div>
		<div class="previewoverlay__content">
			<template v-if="ispdf">
				<div v-if="!forceDownload" class="previewoverlay__content__show">
					<div v-if="pdflink != ''" class="pdfbox">
						<vue-pdf-embed :source="pdflink" :page="page" />
					</div>
				</div>
			</template>
			<template v-else>
				<div v-if="!forceDownload" class="previewoverlay__content__show"></div>
			</template>
			<div v-if="forceDownload" class="previewoverlay__content__download">
				<div class="content">
					<div class="forcedownload__text">
						<template v-if="filetobig">
							{{ $t('pages.areariservata.bigfile') }}
						</template>
						<template v-else>
							{{ $t('pages.areariservata.nopreview') }}
						</template>
					</div>
					<a href="javascript:void(0);" class="forcedownload__btn" @click="downloadFile()">
						<span class="forcedownload__btn__icon">
							<svg viewBox="0 0 14 22">
								<use xlink:href="#icons--download" width="100%" height="100%"></use>
							</svg>
						</span>
						<span class="forcedownload__btn__label">
							{{ $t('pages.areariservata.scarica') }}
						</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
const fileDownload = require('js-file-download');
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
	name: 'PreviewOverlay',
	components: {
		VuePdfEmbed
	},
	data () {
		return {
			docData: null,
			forceDownload: false,
			showContent: null,
			downloadContent: null,
			openCount: 0,
			ispdf: false,
			pdflink: '',
			filetobig: false,
			page: null,
			pageCount: 1,
			showAllPages: true,
		}
	},
	watch: {
		showAllPages() {
			this.page = this.showAllPages ? null : 1;
		}
	},
	methods: {
		downloadFile () {
			// const pattern = /".*?"/g;
			// const filename = pattern.exec(this.docData.res.headers["content-disposition"])
			// const downloadFileName = filename[0].replace(/"/g, "").trim();
			let extension = this.docData.data.mime_type.split('/').reverse()[0];
			if(extension.indexOf('spreadsheet') > 0) {
				extension = "xlsx";
			}
			if(extension.indexOf('presentation') > 0) {
				extension = "pptx";
			}
			fileDownload(this.docData.res.data, `${this.docData.data.name}.${extension}`);
		},
		bytesToSize (bytes) {
			return bytes / (Math.pow(1024, 2));
		},
		createFile (image) {
			return new Promise(resolve => {
				const reader = new FileReader();
				reader.addEventListener('loadend', () => {
					resolve(reader.result);
				})
				reader.addEventListener('error', (event) => {
					console.log(event);
				})
				reader.readAsDataURL(image.data);
			})
		},
		init () {
			let that = this;
			this.showContent = this.$el.querySelector('.previewoverlay__content__show');
			this.downloadContent = this.$el.querySelector('.previewoverlay__content__download');

			this.openCount = 0;

			this.$root.$on('open-preview', (info) => {
				this.pdflink = '';
				this.ispdf = false;
				this.filetobig = false;
				if(this.openCount == 0) {
					this.openCount = this.openCount + 1;

					this.docData = info;
					that.$el.classList.add('showme');

					// st
					that.$router.push(info.data.id);

					if (info && info.data && info.data.size) {
						let type = info.data.mime_type.split('/');
						let fileSize = parseInt(this.bytesToSize(info.data.size));
						if(fileSize < 10) {
							switch (type[0]) {
								case 'image':
									this.ispdf = false;
									this.createFile(info.res).then(value => {
										that.showContent.innerHTML = `<img src="${value}" />`;
									}).catch(err => {
										console.log('errore', err);
									})
									break;
								case 'application':
									if (type[1] == 'pdf' && !this.docData.ismobile) {
										this.ispdf = true;
										const urlObject = URL.createObjectURL(info.res.data);
										this.pdflink = urlObject;
										that.$forceUpdate();
										// that.showContent.innerHTML = `<iframe src="${urlObject}" frameborder="0"></iframe>`
									} else {
										this.forceDownload = true;
									}
									break;
								default:
									this.forceDownload = true;
									this.ispdf = false;
									break;
							}
						} else {
							this.forceDownload = true;
							this.filetobig = true;
						}
					}

					window.onhashchange = function() {
						if (window.innerDocClick) {
							//
						} else {
							that.closePreview();
						}
					}
				}
			})

		},
		closePreview () {
			this.ispdf = false;
			this.openCount = 0;
			if (this.showContent) {
				this.showContent.innerHTML = '';
				this.forceDownload = false;
			}
			this.$el.classList.remove('showme');
		}
	},
	mounted () {
		this.init();
	}
}
</script>
<style lang="scss">
.previewoverlay {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	background: $white;
	@include size(100%, 100vh);
	overflow: hidden;
	z-index: 10000;
	pointer-events: none;
	opacity: 0;
	transition: 180ms linear;

	&__head {
		display: flex;
		@extend %flexCenterEnd;
		background: $white;
		@include size(100%, var(--head));

		&__close {
			display: flex;
			@extend %flexCenterCenter;
			@include size(var(--icon), var(--icon));
			@extend %arrowClose;
			position: relative;
			cursor: pointer;
			transition: 180ms linear;

			&:before,
			&:after {
				background: $black;
				@include size(60%, 2px);
			}

			&:hover {
				transform: rotate(90deg);
			}
		}

		&__download {
			display: flex;
			background: $green;
			@extend %flexCenterEnd;
			width: max-content;
			padding: 6px 15px;
			border-radius: 6px;
			margin-right: var(--minigutter);
			transition: 180ms linear;

			&__label {
				display: block;
				font-family: $bold;
				font-size: 0.8rem;
				color: $white;
				text-transform: lowercase;
			}

			&__icon {
				display: flex;
				@extend %flexCenterCenter;
				@include size(var(--miniicon), var(--miniicon));
				margin-right: 10px;

				svg {
					display: block;
					@include size(100%, 100%);
					fill: $white;
				}
			}

			&:hover {
				background: $black;
			}
		}
	}

	&__content {
		display: block;
		background: $certbg;
		@include size(100%, calc(100% - var(--head)));
		overflow: hidden;

		&__show {
			display: block;
			@include size(100%, 100%);

			img {
				display: block;
				@include size(100%, 100%);
				object-fit: scale-down;
			}

			iframe {
				display: block;
				@include size(100%, 100%);
			}
			.pdfbox {
				display: block;
				@include size(100%,100%);
				overflow-y: auto;
			}
			.pdfviewer {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: auto;
			}
		}

		&__download {
			display: block;
			@include size(100%, 100%);
			overflow: hidden;

			.content {
				display: flex;
				flex-direction: column;
				@extend %flexCenterCenter;
				height: 100%;
			}

			.forcedownload {
				&__btn {
					display: flex;
					background: $green;
					@extend %flexCenterCenter;
					padding: 6px 15px;
					margin-top: calc(var(--gutter) * 2);
					border-radius: 6px;
					transition: 180ms linear;

					&__icon {
						display: flex;
						@extend %flexCenterCenter;
						@include size(var(--miniicon), var(--miniicon));

						svg {
							display: block;
							@include size(100%, 100%);
							fill: $white;
						}
					}

					&__label {
						font-family: $bold;
						color: $white;
						text-transform: lowercase;
						font-size: 1.2rem;
						margin-left: var(--minigutter);
					}

					&:hover {
						background: $black;
					}
				}
				&__text {
					text-transform: lowercase;
				}
			}
		}
	}

	&.showme {
		opacity: 1;
		pointer-events: all;
	}
}
</style>
