<template>
	<div id="showroom" class="showroom main-content" ref="showroom">
		<div class="content small-content">
			<h2 class="page--title">
				{{ $t('showroom.title') }}
			</h2>
			<div class="showroom__content">
				<div class="instructions">
					<div class="instructions__text">
						{{ $t('showroom.text') }}
					</div>
				</div>
				<VisitRequest />
			</div>
		</div>
	</div>
</template>
<script>
import VisitRequest from '@/components/common/VisitRequest';

export default {
	name: 'ShowroomVisit',
	components: {
		VisitRequest
	},
	mounted() {
		window.scrollTo(0, 0);
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/recupera-password';
#showroom * {
	text-transform: lowercase;
}
</style>