<template>
	<div class="form__box">
		<form id="richiesta-accesso" class="form" name="">
			<fieldset class="form__fieldset">
				<span class="form__field field__input">
					<label for="Nome" class="field__label">
						{{ $t('showroom.firstname') }}
					</label>
					<input type="text" class="form__input" id="firstname" name="firstname" v-model="form.first_name" />
					<span v-if="isError('first_name_required')" class="request-error" aria-live="polite">{{ $t('errors.users.first_name_required') }}</span>
				</span>
				<span class="form__field field__input">
					<label for="Cognome" class="field__label">
						{{ $t('showroom.lastname') }}
					</label>
					<input type="text" class="form__input" id="lastname" name="lastname" v-model="form.last_name" />
					<span v-if="isError('last_name_required')" class="request-error" aria-live="polite">{{ $t('errors.users.last_name_required') }}</span>
				</span>
				<span class="form__field field__input">
					<label for="Email" class="field__label">
						{{ $t('showroom.email') }}
					</label>
					<input type="email" class="form__input" id="request_email" name="request_email" v-model="form.email" />
					<span v-if="isError('email_required')" class="request-error" aria-live="polite">{{ $t('errors.users.email_required') }}</span>
					<span v-if="isError('email_not_valid')" class="request-error" aria-live="polite">{{ $t('errors.users.email_not_valid') }}</span>
				</span>
				<span class="form__field field__input">
					<label for="company_name" class="field__label">
						{{ $t('showroom.company') }}
					</label>
					<input type="text" class="form__input" id="company_name" name="company_name" v-model="form.company_name" />
					<span v-if="isError('company_name_required')" class="request-error" aria-live="polite">{{ $t('errors.users.company_name_required') }}</span>
				</span>
			</fieldset>
			<fieldset class="form__fieldset">
				<label for="privacy-check" id="check-privacy-status" class="form__privacy checkradio-box">
					<input type="checkbox" name="privacy-check" class="checkradio-box--input" id="privacy-check" v-model="privacycheck" />
					<span class="fake-check"></span>
					<span class="checkradio-box--label">
						{{ $t('showroom.agree') }}
						<small class="privacy-policy__text" v-html="$t('showroom.privacy')">
							
						</small>
					</span>
				</label>
			</fieldset>
			<div class="form__btns">
				<button type="button" id="submit-form" class="form__btn discover" @click="onSubmit" :disabled="!privacycheck">
					<span class="discover__circle"></span>
					<span class="discover__label">{{ $t('showroom.send') }}</span>
				</button>
			</div>
			<div v-if="isError('email_not_unique')" class="form__errors">
				{{ $t('showroom.errors.email_not_unique') }}
			</div>
		</form>
	</div>
</template>
<script>
export default {
	name: 'VisitRequest',
	data() {
		return {
			states: [],
			form: {
				first_name: '',
				last_name: '',
				email: '',
				company_name: '',
			},
			errors: [],
			privacycheck: false,
		}
	},
	watch: {
		privacycheck: {
			immediate: false,
			deep: true,
			handler(newval,oldval) {
				return newval;
			}
		}
	},
	methods: {
		onSubmit() {
			this.service.users.showroom(this.form).then(res => {
				this.$root.$emit('request-visit');
			}).catch(err => {
				err.messages.forEach(m => {
					this.errors.push(m);
				})
			});
			// return;
		},
		isError(name) {
			return this.errors.indexOf(name) >= 0;
		},
		init() {
			let states = this.$helpers.getStates();
			Object.keys(states).forEach(key => {
				this.states.push(states[key]);
			});
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.instructions {
	display: block;
	width: 100%;
	margin: var(--gutter) auto;
	&__text {
		display: block;
		width: 100%;
		text-align: left;
		margin: 0;
	}
	@media (min-width: $to-medium) {
		width: 75%;
		&__text {
			width: 60%;
		}
	}
}
.form {
	margin-top: calc(var(--gutter) * 2);
	.dropdown__item {
		& * {
			pointer-events: none;
		}
	}
	&__fieldset,
	&__btns {
		&.centered {
			.form__field,
			.form__btn {
				margin: 0 auto;
			}
			input {
				&.psw-error {
					border-color: $red;
				}
			}
		}
	}
	&__btn {
		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}
	&__errors {
		display: block;
		width: 100%;
		padding: var(--gutter);
		background: rgba($red, 0.2);
		color: $black;
		text-align: center;
		text-transform: uppercase;
	}
	.input-box {
		display: block;
		width: 100%;
		position: relative;
		.show-psw {
			position: absolute;
			top: 50%;
			right: 0;
			display: block;
			@include size(var(--miniicon),var(--miniicon));
			transform: translateY(-50%);
			cursor: pointer;
			&__show,
			&__hide {
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				@extend %flexCenterCenter;
				@include size(100%,100%);
				pointer-events: none;
				svg {
					display: block;
					@include size(100%,100%);
					fill: $black;
				}
			}
			&__hide {
				display: none;
			}
			&.show {
				.show-psw__show {
					display: none;
				}
				.show-psw__hide {
					display: block;
				}
			}
		}
	}
	.error {
		display: block;
		width: 100%;
		text-align: center;
		color: $red;
	}
}
</style>