<template>
	<div id="disclaimer" class="disclaimer" :class="show ? 'show' : ''">
		<div class="disclaimer__box">
			<span class="disclaimer__box__title" v-html="$t('disclaimer.title')"></span>
			<span class="disclaimer__box__text" v-html="$t('disclaimer.text')"></span>
			<span class="discover" @click="acceptDisclaimer">
				<span class="discover__circle"></span>
				<span class="discover__label">{{ $t('disclaimer.btn') }}</span>
			</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Disclaimer',
	data() {
		return {
			show: false,
		}
	},
	methods: {
		acceptDisclaimer() {
			localStorage.setItem('disclaimer', true);
			this.show = false;
		},
		init() {
			this.$root.$on('show-disclaimer', () => {
				console.log('EMIT');
				this.show = true;
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.disclaimer {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	@extend %flexCenterCenter;
	background: rgba($black, 0.8);
	@include size(100%,100vh);
	z-index: 100001;
	opacity: 0;
	pointer-events: none;
	&__box {
		display: flex;
		flex-direction: column;
		background: $white;
		padding: var(--gutter);
		width: 90vw;
		height: 80vh;
		opacity: 0;
		transform: translateY(100px);
		overflow: hidden;
		&__title {
			display: block;
			width: 100%;
			font-weight: 700;
			font-size: 1rem;
			line-height: 1.2;
			margin: var(--gutter) 0;
			text-transform: uppercase;
		}
		&__text {
			display: block;
			width: 100%;
			font-size: 0.778rem;
			line-height: 1.3;
			overflow: auto;
			flex-shrink: 1;
		}
		.discover {
			display: flex;
			@extend %flexCenterStart;
			max-width: min-content;
			font-family: $bold;
			color: $black;
			font-size: 1rem;
			line-height: 1.188rem;
			position: relative;
			margin: var(--gutter) auto 0;
			padding: 0 0 0 var(--gutter);
			cursor: pointer;
			&__circle, &__label { pointer-events: none; }
			&__circle {
				position: absolute;
				top: 50%;
				left: 0;
				display: block;
				@include size(10px,10px);
				border-radius: 100%;
				border: 2px solid $black;
				margin-top: -5px;
				margin-right: var(--gutter);
			}
			&__label {
				display: block;
				white-space: nowrap;
			}
		}

	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.disclaimer__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
	@media (min-width: $to-small) {
		&__box {
			@include size(674px,660px);
			&__text {
				font-size: 0.889rem;
			}
			.discover {
				font-size: 1.563rem;
			}
		}
	}
	@media (min-width: $to-large) {
		&__box {
			@include size(800px,660px);
			.discover {
				margin-top: calc(var(--gutter) * 1.5);
			}
		}
	}
}
</style>