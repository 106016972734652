<template>
	<div>
		<template v-if="isLogged">
			<div id="repository" class="repository main-content" ref="repository">
				<div class="content small-content">
					<span v-if="userdata" class="welcomeuser">
						{{ $t('common.welcome') }} <span v-if="userdata.first_name" class="welcomeuser__name">{{ userdata.first_name }}</span> <span v-if="userdata.last_name" class="welcomeuser__name">{{ userdata.last_name }}</span>
					</span>
					<h1 class="page--title" :class="{'hidden': isLogged}">
						{{ $t('pages.home.title') }}
					</h1>
					<template v-if="dataLoading">
						<Loader :label="$t('loading.loading')" />
					</template>
					<template v-else>
						<div class="repo-box">
							<div v-if="isDownloading" class="download-loader">
								<template v-if="downloaderror">
									<span class="download-status error">
										<span class="download-status__icon">
											<svg viewBox="0 0 35 35">
												<use xlink:href="#icons--error" width="100%" height="100%"></use>
											</svg>
										</span>
										<span class="download-status__label">
											{{ $t('loading.downloaderror') }}
										</span>
									</span>
								</template>
								<template v-else-if="downloadok">
									<span class="download-status">
										<span class="download-status__icon">
											<svg viewBox="0 0 34 31">
												<use xlink:href="#icons--check" width="100%" height="100%"></use>
											</svg>
										</span>
										<span class="download-status__label">
											{{ $t('loading.downloadok') }}
										</span>
									</span>
								</template>
								<template v-else>
									<Loader :label="$t('loading.download')" />
								</template>
							</div>
							<div class="repo--text" :class="{'hidden': isLogged}">
								{{ $t('common.intro_text') }}
							</div>
							<div class="repo--utilities">
								<div class="repo--breadcrumbs">
									<Breadcrumb v-if="allDocs && allDocs.length" :data="allDocs" />
								</div>
								<div class="repo--buttons">
									<span class="repo--btn download" @click="startDownload"  v-if="this.$route.params.id1" >
										<span class="repo--btn__icon">
											<svg viewBox="0 0 14 17">
												<use xlink:href="#icons--download"></use>
											</svg>
										</span>
										<span class="repo--btn__label">
											{{ $t('common.download') }}
										</span>
									</span>
								</div>
							</div>

							<div class="repo--list">
								<span v-for="(d, dI) in docs" :key="dI" class="repo--item">
									<template v-if="d.folder">
										<Folder :data="d" />
									</template>
									<template v-if="!d.folder">
										<FilePreview :data="d" />
									</template>
								</span>
							</div>
						</div>
					</template>
				</div>
			</div>
		</template>
		<template v-else>
			<div id="home" class="home main-content" ref="home">
				<div class="content small-content">
					<h1 class="page--title">
						{{ $t("pages.home.title") }}
					</h1>
					<form class="home--login" novalidate @submit.prevent="submitForm">
						<span class="page--title">
							{{ $t('login.form.title') }}
						</span>
						<div class="login--field box-errors" v-if="error">
							<span>
								{{ $t(`login.errors.${error}`) }}
							</span>
						</div>
						<div class="login--field">
							<div class="focused" :class="getValidationClass('email')">
								<label for="email">
									{{ $t("login.form.email") }}
								</label>
								<input type="text" id="email" name="email" autocomplete="email" v-model="form.email" :disabled="loading"/>
								<span class="error" v-if="!$v.form.email.required">
									{{ $t("login.errors.email-required") }}
								</span>
								<span class="error" v-else-if="!$v.form.email.email">
									{{ $t("login.errors.not-email") }}
								</span>
							</div>
						</div>
						<div class="login--field">
							<div class="focused" :class="getValidationClass('password')">
								<label for="password">
									{{ $t("login.form.password") }}
								</label>
								<span class="input-box">
									<input type="password" id="password" name="password" autocomplete="password" v-model="form.password" :disabled="loading"/>
									<span class="show-psw" @click="togglePswVisibility">
										<span class="show-psw__show">
											<svg viewBox="0 0 52 26">
												<use xlink:href="#icons--hidepsw" width="100%" height="100%"></use>
											</svg>
										</span>
										<span class="show-psw__hide">
											<svg viewBox="0 0 52 30">
												<use xlink:href="#icons--showpsw" width="100%" height="100%"></use>
											</svg>
										</span>
									</span>
								</span>
								<span class="md-error" v-if="!$v.form.password.required">
									{{ $t("login.errors.password-required") }}
								</span>
							</div>
						</div>
						<template v-if="!loading">
							<div class="login--ctas">
								<a href="javascript:void(0);" class="login--ctas__recover simple-link" @click="recoverPassword">
									{{ $t('login.form.recover') }}
								</a>
								<button type="submit" :disabled="loading" class="fake-btn">
									<Discover :label="$t('login.form.submit')" />
								</button>
							</div>
						</template>
					</form>
				</div>
				<div class="home--richiedi">
					<figure class="richiedi--img">
						<template v-if="windowWidth > 700">
							<img src="~@/assets/images/login_desktop.jpg" alt="" />
						</template>
						<template v-else>
							<img src="~@/assets/images/login_mobile.jpg" alt="" />
						</template>
					</figure>
					<span class="richiedi--info">
						<div class="richiedi--info__text">
							<span class="page--title">
								{{ $t('login.form.banner.title') }}
							</span>
							<span class="info--text">
								{{ $t('login.form.banner.text') }}
							</span>
						</div>
						<a href="/#/request-access" @click="closeLogin">
							<Discover :label="$t('login.form.banner.btn')" />
						</a>
					</span>
				</div>
			</div>
		</template>
		<Disclaimer />
	</div>
</template>

<script>
const fileDownload = require('js-file-download');
import Breadcrumb from '@/components/common/Breadcrumb';
import Folder from '@/components/common/Folder';
import FilePreview from '@/components/common/FilePreview';

import Discover from '@/components/common/Discover';
import Loader from '@/components/common/Loader';

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { email } from 'vuelidate/lib/validators'

import Disclaimer from '@/components/common/Disclaimer';

export default {
	name: 'Home',
	mixins: [validationMixin],
	components: {
		Discover,
		Breadcrumb,
        Folder,
        FilePreview,
		Loader,
		Disclaimer
	},
	data() {
		return {
			windowWidth: 0,
			iserror: false,
			docs: null,
			langs: [],
			isLogged: false,
			allDocs: null,
            selected: [],
			isLogin: true,
			show: false,
			submitted: false,
			error: '',
			loading: false,
			form: {
				email: '',
				password: ''
			},
			dataLoading: true,
			isDownloading: false,
			downloadok: false,
			downloaderror: false,
			userdata: null,
			richiediImage: null,
		}
	},
	validations: {
		form: {
			email: {
				required,
				email
			},
			password: {
				required
			}
		}
	},
	watch: {
		'$route.params': {
			deep: true,
			immediate: false,
			handler(newValue, oldValue) {
				if(oldValue !== newValue) {
					this.getDocs();
				}
			}
		},
		windowWidth(newWidth, oldWidth) {
			return newWidth;
		},
	},
	methods: {
		togglePswVisibility(item) {
			let input = item.target.parentNode.querySelector('input');

			item.target.classList.contains('show') ? item.target.classList.remove('show') : item.target.classList.add('show')

			if(input.type == 'password') {
				input.type = 'text';
			} else {
				input.type = 'password';
			}

		},
		checkLogin() {
			if(!(localStorage.getItem('token') && localStorage.getItem('token') != '')) {
				return false;
			} else {
				return true;
			}
		},
		recoverPassword() {
			this.$root.$emit('recover-password');
		},
		closeLogin() {
			this.$el.classList.remove('show')
		},
		getValidationClass (fieldName) {
			if (this.$v) {
				const field = this.$v.form[fieldName]
				if (field) {
					return {
						'invalid': field.$invalid && field.$dirty
					}
				}
			}
		},
		submitForm () {
			this.$v.form.$touch();
			this.submitted = true;
			this.error = '';
			this.loading = true;

			let cose = document.querySelector('#login-overlay');

			if (!this.$v.form.$invalid) {
				this.$api.login(this.form.email, this.form.password)
				.then((data) => {
					// this.$router.push('/');
					// this.$router.go();
					window.location.href = '/';
					this.loading = false;
				})
				.catch((err) => {
					cose.classList.remove('show');
					this.error = err.messages;
					this.loading = false;
				})

			} else {
				this.loading = false;
			}
		},
		getChildren(docs, params, level) {
			level = level || 1;
			if(params && params.length) {
				let subdocs = docs.filter(d => {
					return d.id == params[level - 1];
				})
				if(params.length > level) {
					return this.getChildren(subdocs[0].children, params, level + 1);
				} else {
					if(subdocs.length && subdocs[0].children) {
						return subdocs[0].children;
					}
					return [];
				}
			} else {
				return docs;
			}
		},
        getDocs() {
            this.docs = [];
			this.allDocs = [];
			this.dataLoading = true;

            this.service.documents.list(1, 'name', 12, {}).then((docs) => {
				this.iserror = false;
				let levels = [];

				Object.keys(this.$route.params).forEach(id => {
					if(id && this.$route.params[id]) {
						levels.push(this.$route.params[id]);
					}
				});

				this.docs = this.getChildren(docs, levels);
				this.allDocs = docs;

				this.$store.state.docs = docs
            }).catch(err => {
				console.log('ERROR', err);
				this.iserror = true;
				this.$root.$emit('session-error');
				// localStorage.removeItem('token');
				// this.isLogged = false;
				// window.location.href = '/#/?e=timeout';
				// this.$forceUpdate();
			}).finally(() => {
				this.dataLoading = false;
			});
        },
        startDownload() {
			if(this.docs && this.docs.length) {
				const documents = Array.from(this.$el.querySelectorAll('.item--input'));
				this.selected = [];
				documents.forEach(d => {
					if(d.classList.value.indexOf('selected') >= 0) {
						let id = d.getAttribute('id');
						this.selected.push(id);
                    }
                });
				if(this.selected && (this.selected.length > 0)) {
					this.isDownloading = true;
					this.service.documents.zip(this.selected).then(res => {
						const pattern = /".*?"/g;
						const filename = 'pedrali_files.zip';
						fileDownload(res, filename);
					}).then(() => {
						this.downloadok = true;
					}).catch(err => {
						console.log('ERROR', err);
						this.downloaderror = true;
					}).finally(() => {
						if(this.downloadok) {
							this.downloaderror = false;
						}
						setTimeout(() => {
							this.isDownloading = false;
							this.downloaderror = false;
							this.downloadok = false;
							this.selected = [];
							this.$root.$emit('deselect');
						}, 3000)
					});
				} else {
					this.$root.$emit('no-files');
				}
            }
        },
		onResize() {
			this.windowWidth = window.innerWidth;

		},
	},
	mounted() {
		window.scrollTo(0, 0);
		this.windowWidth = window.innerWidth;

		this.isLogged = this.checkLogin();

		if(this.isLogged) {
			console.log('SONO LOGGATO');
			let firstVisit = localStorage.getItem('disclaimer');

			if(!firstVisit) {
				console.log('SHOW DISCLAIMER');
				this.$root.$emit('show-disclaimer');
			}
			this.getDocs();
			this.$forceUpdate();
		}
		this.$root.$on('cambio-lingua', () => {
			window.location.href = '/';
		})

		this.$root.$on('get-user', () => {
			let userToken = localStorage.getItem('token');
			this.service.users.profile({ token: userToken}).then(res => {
				if(res && res.user) {
					this.userdata = res.user;
				}
			}).catch(err => {
				this.$root.$emit('session-error');
				// localStorage.removeItem('token');
				// this.isLogged = false;
				// window.location.href = '/';
				// this.$forceUpdate();
				console.log('ERROR', err);
			});
		})
	}
}
</script>
<style lang="scss">
@import "~@/assets/scss/pages/home";
@import "~@/assets/scss/pages/repository";
.repo-box {
	display: block;
	position: relative;
	.download-loader {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		background: rgba($white, 0.9);
		@extend %flexStartCenter;
		@include size(100%,100%);
		z-index: 100;
	}
	.download-status {
		display: flex;
		@extend %flexCenterStart;
		flex-direction: column;
		&__icon {
			display: flex;
			@extend %flexStartCenter;
			@include size(var(--icon),var(--icon));
			svg {
				display: block;
				@include size(100%,100%);
				fill: $green;
			}
		}
		&__label {
			display: block;
			font-family: $bold;
			font-size: 1.4rem;
			color: $green;
			margin-top: var(--gutter);
			text-align: center;
		}
		&.error {
			.download-status {
				&__icon svg { fill: $red; }
				&__label { color: $red; }
			}
		}
	}
}

.welcomeuser {
	display: block;
	width: 100%;
	margin-bottom: var(--gutter);
	&__name {
		font-family: $bold;
	}
}

.page--title,
.repo--text {
	&.hidden {
		display: none !important;
	}
}

.input-box {
	display: block;
	width: 100%;
	position: relative;
	.show-psw {
		position: absolute;
		top: 50%;
		right: 0;
		display: block;
		@include size(var(--miniicon),var(--miniicon));
		transform: translateY(-50%);
		cursor: pointer;
		&__show,
		&__hide {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			@extend %flexCenterCenter;
			@include size(100%,100%);
			pointer-events: none;
			svg {
				display: block;
				@include size(100%,100%);
				fill: $black;
			}
		}
		&__hide {
			display: none;
		}
		&.show {
			.show-psw__show {
				display: none;
			}
			.show-psw__hide {
				display: block;
			}
		}
	}
}
</style>
