<template>
	<div id="disclaimerpage" class="disclaimerpage main-content" ref="disclaimer">
		<div class="content small-content">
			<h2 class="disclaimerpage__title" v-html="$t('disclaimer.title')"></h2>
			<div class="disclaimerpage__text" v-html="$t('disclaimer.text')">
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'DisclaimerPage',
	mounted() {
		window.scrollTo(0, 0);
	}
}
</script>
<style lang="scss">
.disclaimerpage {
	display: block;
	width: 100%;
	&__title {
		display: block;
		width: 100%;
		font-weight: 700;
		font-size: 1rem;
		line-height: 1.2;
		margin: var(--gutter) 0;
		text-transform: uppercase;
	}
	&__text {
		display: block;
		width: 100%;
		font-size: 1rem;
		line-height: 1.3;
		overflow: auto;
		max-height: 60%;
	}
}
</style>