<template>
  <label :for="data.id" class="folder" @dblclick="openFolder" @click="handleClick">
    <input type="checkbox" class="folder--input item--input" :name="data.id" :id="data.id"/>
    <span class="fake-checkbox" v-if="this.$route.params.id1">
            <svg viewBox="0 0 34 31">
                <use xlink:href="#icons--check" width="100%" height="100%"></use>
            </svg>
        </span>
    <span class="folder--icon o-ratio u-1:1">
            <svg viewBox="0 0 212 178">
                <use xlink:href="#icons--folder" width="100%" height="100%"></use>
            </svg>
        </span>
    <span class="folder--label">
            {{ data.name }}
        </span>
  </label>
</template>

<script>

export default {
  name: 'Folder',
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      windowWidth: 0,
      interval: false,
      timer: 0,
    };
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      return newWidth;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    openFolder() {
      if (this.data) {
        let levels = [];

        Object.keys(this.$route.params).forEach(id => {
          if (id && this.$route.params[id]) {
            levels.push(this.$route.params[id]);
          }
        });

        levels.push(this.data.id);

        localStorage['folder'] = this.data.id;

        this.$router.push(`/${levels.join('/')}`);

        this.$forceUpdate();
      }
    },
    handleClick(item) {
      item.target.classList.contains('selected') ? item.target.classList.remove('selected') : item.target.classList.add('selected');
      // if(!this.interval) {
      //     this.interval = setInterval(() => {
      //         this.timer++;
      //         if(this.timer < 4) {
      // 			if(this.windowWidth > 1200) {
      // 			} else {
      // 				if(this.data) {
      // 					let levels = [];

      // 					Object.keys(this.$route.params).forEach(id => {
      // 						if(id && this.$route.params[id]) {
      // 							levels.push(this.$route.params[id]);
      // 						}
      // 					})

      // 					levels.push(this.data.id);

      // 					this.$router.push(`/${levels.join('/')}`);

      // 					this.$forceUpdate();
      // 				}
      // 			}
      // 		}
      //     }, 300);
      // }

    },
    selectItem(item) {
      item.target.querySelector('input').checked = true;
      item.target.classList.contains('selected') ? item.target.classList.remove('selected') : item.target.classList.add('selected');
      // if(!this.interval) {
      //     this.interval = setInterval(() => {
      //         this.timer++;
      //         if(this.timer > 1) {
      //             clearInterval(this.interval);
      //             this.interval = false;
      //         }
      //     }, 300);
      // }
    },
    cancelSelection(item) {
      // this.timer = 0;
      // clearInterval(this.interval);
      // this.interval = false;
      // if(this.timer > 1) {
      //     item.target.classList.contains('selected') ? item.target.classList.remove('selected') : item.target.classList.add('selected');
      // }
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.onResize();
      });
    });

    this.$root.$on('deselect', () => {
      let folders = document.querySelectorAll('.selected');
      Array.from(folders).forEach(f => {
        f.classList.remove('selected');
      });
      this.$forceUpdate();
    });
  },
};
</script>

<style lang="scss">
.folder {
  display: block;
  width: calc(100% - var(--minigutter));
  margin: 0 calc(var(--minigutter) / 2) var(--minigutter);
  padding: var(--minigutter);
  position: relative;
  cursor: pointer;

  &--input {
    display: none;
  }

  &--input, .fake-checkbox,
  &--icon, &--label {
    pointer-events: none;
  }

  .fake-checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    @extend %flexCenterCenter;
    @include size(20px, 20px);
    border-radius: 100%;
    border: 1px solid $black;

    svg {
      display: block;
      @include size(60%, 60%);
      fill: $black;
      opacity: 0;
    }
  }

  &--icon {
    display: block;
    width: 75%;
    margin: 0 auto var(--minigutter);

    svg {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      @include size(100%, 100%);
      fill: $timberwolf;
    }
  }

  &--label {
    display: block;
    width: 100%;
    line-height: 1.2;
    text-align: center;
  }

  &.selected {
    background: $certbg;

    .fake-checkbox {
      background: $green;
      border: 0;

      svg {
        opacity: 1;
        fill: $white;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      background: $certbg;

      .fake-checkbox {
        svg {
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: $to-small) {
    // width: calc(33% - var(--minigutter));
    .fake-checkbox {
      @include size(var(--miniicon), var(--miniicon));
    }
  }
  @media (min-width: $to-medium) {
    // width: calc(25% - var(--minigutter));
  }
}
</style>
