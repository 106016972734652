<template>
	<div id="overlayforms" class="overlayform" :class="show ? 'show' : ''">
		<div class="overlayform__box">
			<span class="overlayform__box__close" @click="closeOverlay"></span>
			<span class="overlayform__box__title" v-html="$t('session.error.title')"></span>
			<span class="overlayform__box__text" v-html="$t('session.error.text')"></span>
			<span class="discover" @click="closeOverlay">
				<span class="discover__circle"></span>
				<span class="discover__label">{{ $t('session.login') }}</span>
			</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'OverlaySession',
	data() {
		return {
			show: false,
		}
	},
	methods: {
		closeOverlay() {
			localStorage.removeItem('token');
			this.isLogged = false;
			window.location.href = '/';
			this.$forceUpdate();
		},
		init() {
			this.$root.$on('session-error', () => {
				this.show = true;
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.overlayform {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	@extend %flexCenterCenter;
	background: rgba($black, 0.8);
	@include size(100%,100vh);
	z-index: 100001;
	opacity: 0;
	pointer-events: none;
	&__box {
		display: block;
		background: $white;
		padding: var(--gutter);
		width: 90vw;
		max-width: 90vw;
		opacity: 0;
		transform: translateY(100px);
		&__title {
			display: block;
			width: 100%;
			font-weight: 700;
			font-size: 2.063rem;
			line-height: 1;
			margin: var(--gutter) 0;
		}
		&__text {
			display: block;
			width: 100%;
			line-height: 1.3;
		}
		&__close {
			float: right;
			display: block;
			@include size(20px,20px);
			position: relative;
			@extend %arrowClose;
			cursor: pointer;
			&:before,
			&:after {
				background: $black;
				@include size(80%,1px);
			}
		}
		.form {
			width: 100% !important;
			&__fieldset {
				margin-bottom: 0;
			}
			&__field {
				width: 100% !important;
			}
			&__btns {
				margin-top: 0;
			}
			&__btn {
				margin-top: var(--gutter);
			}
			.recover-error {
				display: block;
				width: 100%;
				text-align: center;
				color: $red;
			}
		}
	}
	.discover {
		display: flex;
		@extend %flexCenterStart;
		max-width: min-content;
		font-family: $bold;
		color: $black;
		font-size: 1rem;
		line-height: 1.188rem;
		position: relative;
		margin: var(--gutter) 0 0;
		padding: 0 0 0 var(--gutter);
		cursor: pointer;
		&__circle, &__label { pointer-events: none; }
		&__circle {
			position: absolute;
			top: 50%;
			left: 0;
			display: block;
			@include size(10px,10px);
			border-radius: 100%;
			border: 2px solid $black;
			margin-top: -5px;
			margin-right: var(--gutter);
		}
		&__label {
			display: block;
			white-space: nowrap;
		}
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.overlayform__box {
			opacity: 1;
			transform: translateY(0);
		}
	}
	@media (min-width: $to-medium) {
		&__box {
			max-width: 450px;
		}
	}
}
</style>