<template>
	<div id="appdownload" class="appdownload main-content" ref="appdownload">
		<div class="content small-content">
			<h2 class="appdownload__title page--title">
				pedrali app
			</h2>
			<div class="appdownload__text">
				{{ $t('downloadapp') }}
			</div>
			<div class="appdownload__ctas">
				<a href="itms-services://?action=download-manifest&amp;url=https://developers.mwd.digital/apps/pedrali/manifest.plist" target="_blank" class="appdownload__ctas__store">
					<img src="~@/assets/images/appstore.png" alt="" />
				</a>
				<a href="https://play.google.com/store/apps/details?id=digital.mwd.app.pedrali" target="_blank" class="appdownload__ctas__store">
					<img src="~@/assets/images/playstore.png" alt="" />
				</a>
			</div>
			<div class="appdownload__howto">
				<span class="appdownload__howto__trigger" @click="showHowTo">
					Mostra istruzioni per telefoni iOS
				</span>
			</div>
		</div>
		<div class="howto">
			<span class="howto__close" @click="closeHowto"></span>
			<div class="howto__box">
				<div class="howto__steps">
					<div class="howto__steps__stage">
						<div v-for="(s, sI) in steps" :key="sI" class="howto__step" :id="`step_${sI + 1}`">
							<span class="howto__step__text">
								<small class="howto__step__text__label">
									Step {{ sI + 1 }}
								</small>
								{{ s.text }}
							</span>
							<figure class="howto__step__media">
								<template v-if="sI == 0">
									<img class="howto__step__media__image" src="~@/assets/images/steps/step1.jpg" alt="" />
								</template>
								<template v-if="sI == 1">
									<img class="howto__step__media__image" src="~@/assets/images/steps/step2.jpg" alt="" />
								</template>
								<template v-if="sI == 2">
									<img class="howto__step__media__image" src="~@/assets/images/steps/step3.jpg" alt="" />
								</template>
								<template v-if="sI == 3">
									<img class="howto__step__media__image" src="~@/assets/images/steps/step4.jpg" alt="" />
								</template>
								<template v-if="sI == 4">
									<img class="howto__step__media__image" src="~@/assets/images/steps/step5.jpg" alt="" />
								</template>
								<template v-if="sI == 5">
									<img class="howto__step__media__image" src="~@/assets/images/steps/step6.jpg" alt="" />
								</template>
							</figure>
						</div>
					</div>
				</div>
				<div class="howto__controls">
					<span v-for="(s, sI) in steps" :key="sI" class="howto__controls__step" :class="{ 'current': sI == 0}" :id="`control_${sI + 1}`" @click="changeStep">
						{{ sI + 1 }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import gsap from 'gsap';
export default {
	name: 'PedraliApp',
	components: {
	},
	data() {
		return {
			stepSize: 0,
			steps: [
				{
					text: 'Dopo aver scaricato l\'app alla prima apertura uscirà questo messaggio, premere Annulla',
					image: 'step1.jpg'
				},
				{
					text: 'Aprire le Impostazioni',
					image: 'step2.jpg'
				},
				{
					text: 'Selezionare VPN & gestione dispositivo',
					image: 'step3.jpg'
				},
				{
					text: 'Selezionare MWD SRL',
					image: 'step4.jpg'
				},
				{
					text: 'Concedi accesso a MWD SRL',
					image: 'step5.jpg'
				},
				{
					text: 'Al pop-up successivo selezionare "Consenti".',
					image: 'step6.jpg'
				},
			]
		}
	},
	methods: {
		showHowTo() {
			document.querySelector('.howto').classList.add('showme')
		},
		changeStep(step) {
			let current = gsap.utils.toArray('.howto__controls__step.current');

			if(current) {
				current.forEach(c => {
					c.classList.remove('current');
				})
			}

			step.target.classList.add('current');

			let item = step.target.id;
			let id = +item.replace('control_', '');

			this.stepSize = document.querySelector('.howto__step').getBoundingClientRect().width;

			gsap.to('.howto__steps__stage', {
				duration: 0.4,
				left: `${(this.stepSize * -id) + this.stepSize}`
			})
		},
		closeHowto() {
			document.querySelector('.howto').classList.remove('showme')
			gsap.to('.howto__steps__stage', {
				duration: 0.4,
				left: 0
			})

			let steps = gsap.utils.toArray('.howto__step');
			let controls = gsap.utils.toArray('.howto__controls__step');

			document.querySelector('.howto__controls__step.current').classList.remove('current');
			
			controls[0].classList.add('current');
		}
	},
	mounted() {
		window.scrollTo(0, 0);
	}
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/appdownload';
#appdownload * {
	text-transform: lowercase;
}
.howto {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	background: rgba($black, 0.8);
	@extend %flexCenterCenter;
	@include size(100%,100vh);
	z-index: 100000;
	opacity: 0;
	pointer-events: none;
	&__close {
		position: absolute;
		top: var(--minigutter);
		right: var(--minigutter);
		display: block;
		@include size(var(--miniicon),var(--miniicon));
		@extend %arrowClose;
		cursor: pointer;
		&:before,
		&:after {
			background: $white;
			@include size(80%,2px);
		}
	}
	&__box {
		display: block;
		background: $white;
		@include size(80vw,80vh);
		padding: var(--gutter);
	}
	&__steps {
		display: block;
		@include size(100%,calc(100% - 60px));
		overflow: hidden;
		position: relative;
		&__stage {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			@include size(600%,100%);
			transition: 180ms linear;
		}
	}
	&__step {
		display: flex;
		@extend %flexCenterBetween;
		flex-wrap: wrap;
		@include size(calc(100% / 6), 100%);
		overflow: auto;
		&__text {
			display: block;
			width: 100%;
			text-align: center;
			margin-bottom: var(--gutter);
			&__label {
				display: block;
				width: 100%;
				font-family: $bold;
				margin-bottom: var(--minigutter);
				text-align: center;
			}
		}
		&__media {
			display: block;
			@include size(100%,100%);
			&__image {
				display: block;
				@include size(100%,100%);
				object-fit: scale-down;
			}
		}
	}
	&__controls {
		display: flex;
		@extend %flexCenterCenter;
		@include size(100%,60px);
		&__step {
			display: flex;
			@extend %flexCenterCenter;
			@include size(var(--gutter),var(--gutter));
			border-radius: 100%;
			margin: 0 6px;
			cursor: pointer;
			&.current {
				background: $timberwolf;
				pointer-events: none;
			}
		}
	}
	@media (min-width: $to-medium) {
		&__box {
			width: 60vw;
		}
		&__step {
			&__text,
			&__media {
				width: 50%;
			}
			&__text {
				text-align: left;
				&__label {
					text-align: left;
				}
			}
		}
	}
	&.showme {
		opacity: 1;
		pointer-events: all;
	}
}
</style>