<template>
	<nav role="navigation" class="nav">
		<!-- <router-link to="/area-riservata" class="nav__link" @click.native="closeNav">
			{{ $t("pages.home.title") }}
		</router-link> -->
		<!-- <router-link to="/profilo" class="nav__link" @click.native="closeNav">
			Profilo
		</router-link> -->
		<div v-if="customdata" class="userdata">
			<span v-if="customdata.personaldata" class="userdata__name">
				<span class="userdata__name__label">{{ $t('utente.welcome') }}</span> <span v-if="customdata.personaldata.first_name" class="userdata__name__name">{{ customdata.personaldata.first_name }}</span>&nbsp;<span v-if="customdata.personaldata.last_name" class="userdata__name__last">{{ customdata.personaldata.last_name }}</span>
			</span>
			<div v-for="(value, name, userIndex) in customdata" :key="userIndex" class="userdata__section">
				<span class="userdata__label">
					{{ $t(`utente.labels.${name}`) }}
				</span>
				<div v-for="(v, n, vI) in customdata[name]" :key="vI" class="userdata__info">
					<small class="userdata__info__label">
						{{ $t(`utente.${n}`) }}
					</small>
					<span class="userdata__info__value">
						{{ v ? v : '...' }}
					</span>
				</div>
			</div>
		</div>
		<a href="javascript:void(0);" class="nav__link logout" @click="logout">
			Logout
		</a>
	</nav>
</template>
<script>
export default {
	name: 'Menu',
	data() {
		return {
			userdata: null,
			customdata: {
				personaldata: {},
				companydata: {},
				contactsdata: {}
			}
		}
	},
	methods: {
		logout() {
			localStorage.removeItem('token');
			window.location.href = '/';
			this.closeNav();
		},
		closeNav() {
			document.querySelector('#login-overlay').classList.remove('show')
		},
		init() {
			this.$root.$on('get-user', () => {
				let userToken = localStorage.getItem('token');
				this.service.users.profile({ token: userToken }).then(res => {
					if(res && res.user) {
						Object.entries(res.user).forEach(([key, value]) => {
							switch(key) {
								case 'first_name':
								case 'last_name':
									this.customdata.personaldata[key] = value;
									break;
								case 'company_name':
									this.customdata.companydata[key] = value;
									break;
								case 'email':
								case 'city':
								case 'country':
									this.customdata.contactsdata[key] = value;
									break;
							}
						})

						this.$forceUpdate();
						console.log(this.customdata);

					}
				}).catch(err => {
					this.$root.$emit('session-error');
					// localStorage.removeItem('token');
					// this.isLogged = false;
					// window.location.href = '/#/?e=timeout';
					// this.$forceUpdate();
					console.log('ERROR', err);
				});
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.userdata {
	display: block;
	width: 100%;
	&__name,
	&__section {
		display: block;
		width: 100%;
	}
	&__name {
		display: flex;
		@extend %flexBaselineStart;
		flex-wrap: wrap;
		margin-bottom: var(--gutter);
		line-height: 1.4;
		&__label {
			display: block;
			width: 100%;
		}
		&__name, &__last {
			font-family: $bold;
		}
	}
	&__section {
		margin-bottom: var(--gutter);
	}
	&__label {
		display: flex;
		@extend %flexCenterStart;
		width: 100%;
		font-family: $bold;
		font-size: 0.9rem;
		&:before {
			content: '';
			display: block;
			background: $black;
			@include size(8px, 2px);
			margin-right: 6px;
		}
	}
	&__info {
		display: block;
		width: 100%;
		margin: var(--minigutter) 0 calc(var(--minigutter) * 1.5);
		line-height: 1.3;
		&__label,
		&__value {
			display: block;
			width: 100%;
		}
		&__value {
			font-size: 1.4rem;
		}
	}
}
.nav {
	display: block;
	width: 100%;
	margin-top: var(--gutter);
	padding: var(--gutter);
	&__link {
		display: block;
		width: max-content;
		font-family: $bold;
		font-size: 1.7rem;
		color: $black;
		margin: var(--minigutter) 0;
		&.logout {
			font-size: 1rem;
			margin-top: calc(var(--gutter) * 3);
		}
	}
}
</style>