<template>
	<div id="login-overlay" :class="show ? 'show' : ''">
		<div class="overlay--box">
			<span class="overlay--close" @click="closeLogin"></span>
			<!-- <template v-if="isLogin">
				<span class="overlay--title">
					{{ $t('login.form.title') }}
				</span>
				<form class="overlay--login" novalidate @submit.prevent="submitForm">
					<div class="login--field box-errors" v-if="error">
						<span>
							{{ $t(`login.errors.${error}`) }}
						</span>
					</div>
					<div class="login--field">
						<div class="focused" :class="getValidationClass('email')">
							<label for="email">
								{{ $t("login.form.email") }}
							</label>
							<input type="text" id="email" name="email" autocomplete="email" v-model="form.email" :disabled="loading"/>
							<span class="error" v-if="!$v.form.email.required">
								{{ $t("login.errors.email-required") }}
							</span>
							<span class="error" v-else-if="!$v.form.email.email">
								{{ $t("login.errors.not-email") }}
							</span>
						</div>
					</div>
					<div class="login--field">
						<div class="focused" :class="getValidationClass('password')">
							<label for="password">
								{{ $t("login.form.password") }}
							</label>
							<input type="password" id="password" name="password" autocomplete="password" v-model="form.password" :disabled="loading"/>
							<span class="md-error" v-if="!$v.form.password.required">
								{{ $t("login.errors.password-required") }}
							</span>
						</div>
					</div>
					<template v-if="!loading">
						<div class="login--ctas">
							<a href="javascript:void(0);" class="login--ctas__recover simple-link" @click="recoverPassword">
								{{ $t('login.form.recover') }}
							</a>
							<button type="submit" :disabled="loading" class="fake-btn">
								<Discover :label="$t('login.form.submit')" />
							</button>
						</div>
					</template>
				</form>
				<div class="overlay--richiedi">
					<figure class="richiedi--img">
						<img src="~@/assets/images/bg-richiedi.jpg" alt="" />
					</figure>
					<span class="richiedi--info">
						<div class="richiedi--info__text">
							<span class="page--title">
								{{ $t('login.form.banner.title') }}
							</span>
							<span class="info--text">
								{{ $t('login.form.banner.text') }}
							</span>
						</div>
						<a href="/#/request-access" @click="closeLogin">
							<Discover :label="$t('login.form.banner.btn')" />
						</a>
					</span>
				</div>
			</template>
			<template v-else>
				<Menu />
			</template> -->
			<Menu />
		</div>
	</div>
</template>
<script>
// import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
// import { email } from 'vuelidate/lib/validators'
// import Discover from '@/components/common/Discover';
import Menu from '@/components/layout/Menu';

export default {
	name: 'LoginForm',
	// mixins: [validationMixin],
	components: {
		// Discover,
		Menu
	},
	data () {
		return {
			// isLogin: true,
			show: false,
			// submitted: false,
			// error: '',
			// loading: false,
			// form: {
			// 	email: '',
			// 	password: ''
			// }
		};
	},
	// validations: {
	// 	form: {
	// 		email: {
	// 			required,
	// 			email
	// 		},
	// 		password: {
	// 			required
	// 		}
	// 	}
	// },
	methods: {
		// recoverPassword() {
		// 	this.$root.$emit('recover-password');
		// },
		closeLogin() {
			this.$el.classList.remove('show')
		},
		// getValidationClass (fieldName) {
		// 	if (this.$v) {
		// 		const field = this.$v.form[fieldName]
		// 		if (field) {
		// 			return {
		// 				'invalid': field.$invalid && field.$dirty
		// 			}
		// 		}
		// 	}
		// },
		// submitForm () {
		// 	this.$v.form.$touch();
		// 	this.submitted = true;
		// 	this.error = '';
		// 	this.loading = true;

		// 	let cose = document.querySelector('#login-overlay');

		// 	if (!this.$v.form.$invalid) {
		// 		this.$api.login(this.form.email, this.form.password)
		// 		.then((data) => {
		// 			// this.$router.push('/');
		// 			this.$router.go();
		// 			this.loading = false;
		// 		})
		// 		.catch((err) => {
		// 			cose.classList.remove('show');
		// 			this.error = err.messages;
		// 			this.loading = false;
		// 		})

		// 	} else {
		// 		this.loading = false;
		// 	}
		// }
		init() {
			this.$root.$on('show-login', () => {
				this.show = true;
				this.$el.classList.add('show');
				if(!(localStorage.getItem('token') && localStorage.getItem('token') != '')) {
					this.isLogin = true;
				} else {
					this.isLogin = false;
				}
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
#login-overlay {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	background: rgba($black, 0.6);
	@include size(100vw,100vh);
	z-index: 100000;
	opacity: 0;
	pointer-events: none;
	transition: 250ms linear;
	.overlay {
		&--box {
			position: absolute;
			top: 0;
			right: 0;
			display: block;
			background: $white;
			@include size(95vw,100vh);
			padding: var(--gutter) var(--gutter) calc(var(--gutter) * 3);
			overflow-x: hidden;
			overflow-y: auto;
			transform: translateX(100%);
			transition: 250ms linear;
		}
		&--close {
			float: right;
			display: block;
			@include size(20px,20px);
			position: relative;
			@extend %arrowClose;
			cursor: pointer;
			&:before,
			&:after {
				background: $black;
				@include size(80%,1px);
			}
		}
		&--title {
			display: block;
			width: 100%;
			font-size: 2.063rem;
			margin: var(--gutter) 0;
		}
		&--login {
			display: block;
			width: 100%;
			margin-bottom: calc(var(--gutter) * 2);
			.login {
				&--field {
					display: block;
					width: 100%;
					margin-bottom: var(--minigutter);
					.focused {
						display: flex;
						flex-direction: column;
						width: 100%;
						input {
							display: block;
							background: $white;
							@include size(100%,var(--input));
							border: 0;
							border-bottom: 1px solid $black;
							padding: var(--minigutter) 0;
						}
					}
				}
				&--ctas {
					display: flex;
					@extend %flexCenterBetween;
					flex-wrap: wrap;
					&__recover {
						color: $black;
						font-size: 0.8rem;
						margin-right: var(--minigutter);
						pointer-events: all;
						position: relative;
						cursor: pointer;
						&:after {
							content: '';
							position: absolute;
							bottom: 0;
							left: 0;
							display: block;
							background: $black;
							@include size(100%,2px);
							transition: transform 0.36s;
							transform: scaleX(0);
							transform-origin: right;
						}
						&:hover::after {
							transform: scaleX(1);
							transform-origin: left;
						}
					}
					.fake-btn {
						border: 0;
						background: transparent;
						margin: 0;
						padding: 0;
					}
					.discover {
						font-size: 1.3rem;
						padding: var(--minigutter) var(--gutter);
						margin-top: 0;
					}
				}
			}
		}
		&--richiedi {
			display: block;
			width: 100%;
			position: relative;
			.richiedi {
				&--img {
					display: block;
					@include size(100%,75vw);
					position: relative;
					z-index: 1;
					img {
						position: absolute;
						top: 0;
						left: 0;
						display: block;
						@include size(100%,100%);
						object-fit: cover;
						object-position: 30% center;
					}
				}
				&--info {
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					@extend %flexStartBetween;
					flex-direction: column;
					@include size(100%,100%);
					padding: var(--minigutter);
					z-index: 2;
					.page--title {
						line-height: 1.9rem;
						margin-bottom: var(--minigutter);
					}
				}
			}
		}
		@media (min-width: $to-medium) {
			&--box {
				width: 35vw;
				padding: calc(var(--gutter) * 1.5);
			}
			&--richiedi {
				.richiedi {
					&--img {
						height: auto;
						img {
							position: relative;
						}
					}
					&--info {
						padding: var(--gutter);
						&__text {
							max-width: 60%;
							.page--title {
								font-size: 2.3rem;
							}
						}
					}
				}
			}
		}
		@media (min-width: $to-large) {
			&--box {
				padding: calc(var(--gutter) * 2);
			}
		}
	}
	&.show {
		opacity: 1;
		pointer-events: all;
		.overlay--box {
			transform: translateX(0);
		}
	}
}
</style>
