export default {
    install: function (Vue) {
        let service = {};
        Vue.mixin({
            mounted() {

                let helpers = {};

                /**
                 * Crea i parametri per la lista
                 * @param page
                 * @param sort
                 * @param search
                 * @param search_fields
                 * @returns {string}
                 */
                helpers.buildQueryParams = (page, sort) => {
                    page = page || 1;
                    sort = sort || "";
                    let objParams = {
                        page: page,
                        sort: sort,
                    };
                    let params = '';
                    for (var key in objParams) {
                        if (params != "") {
                            params += "&";
                        }
                        params += key + "=" + encodeURIComponent(objParams[key]);
                    }
                    return params;
                };
                service.helpers = helpers;

                /**
                 * Gestione del profilo
                 * @returns {Promise<unknown>}
                 */
                service.profile = () => {
                    let url = `/users/profile`;
                    return this.$api.get(url);
                };

                let documents = {};
                documents.list = () => {
                    let path = '/documents';

                    if (localStorage['folder']) {
                        path += `?folder=${localStorage['folder']}`;
                        localStorage.removeItem('folder');
                    }

                    return this.$api.get(path);
                };

                documents.download = (id) => {
                    return this.$api.getDownload(`/documents/${id}`);
                };

                documents.zip = (ids) => {
                    return this.$api.postDownload(`/zip`, {documents: ids});
                };

                service.documents = documents;

                /**
                 * Richiesta di accesso
                 */
                let users = {};
                users.request = (userData) => {
                    return this.$api.post(`/user/requests`, userData);
                };

                users.getCountries = () => {
                    return this.$api.get(`/countries`);
                };

                users.getPositions = () => {
                    return this.$api.get(`/positions`);
                };

                users.getSectors = () => {
                    return this.$api.get(`/sectors`);
                };

                users.recover = (email) => {
                    return this.$api.post(`/user/recover`, email);
                };

                users.restore = (newdata) => {
                    return this.$api.post(`/user/restore`, newdata);
                };

                users.showroom = (userdata) => {
                    return this.$api.post('/user/showroom', userdata);
                };

                users.profile = (token) => {
                    return this.$api.post('/user/me', token);
                };

                service.users = users;

            },
        });
        Vue.prototype.service = service;
    },
};
