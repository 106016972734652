<template>
    <span class="discover">
        <span class="discover__circle"></span>
        <span class="discover__label">{{ label }}</span>
    </span>
</template>

<script>
export default {
    name: 'Discover',
    props: {
        label: {
            type: String,
            required: true
        }
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped>
.discover {
    display: inline-flex;
    @extend %flexCenterStart;
    max-width: min-content;
    font-family: $bold;
    color: $black;
    font-size: 1.25rem;
    line-height: 1.188rem;
    position: relative;
    margin-top: var(--gutter);
    padding: 0 0 0 var(--gutter);
    &__circle, &__label { pointer-events: none; }
    &__circle {
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        @include size(10px,10px);
        border-radius: 100%;
        border: 2px solid $black;
        margin-top: -5px;
        margin-right: var(--gutter);
    }
    &__label {
        display: block;
        white-space: nowrap;
    }
    &.text-color-white {
        .discover {
            &__circle {
                border: 2px solid $white;
            }
            &__label {
                color: $white;
            }
        }
    }
    &.share {
        .discover__circle {
            border: 2px solid $lightblue;
        }
    }
    &.reqinfo {
        .discover__circle {
            border: 2px solid $red;
        }
    }
    @media (min-width: $to-medium) {
        font-size: 1.563rem;
        line-height: 1.875rem;
        padding: var(--gutter);
    }
}

</style>
