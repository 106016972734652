import Vue from 'vue'
import VueRouter from 'vue-router'
import PasswordReset from '../views/password-reset';
import RequestAccess from '../views/request-access';
import Home from '../views/Home.vue'
import ShowroomVisit from '../views/showroom-visit';
import PedraliApp from '../views/pedrali-app';
import DisclaimerPage from '../views/disclaimer';
// import AreaRiservata from '../views/area-riservata';

Vue.use(VueRouter)

const guardAuthService = async (to, from, next) => {
	try {
		let token = localStorage.getItem('token')
		if (token && token !== '') {
			next()
		} else {
			next()
		}
	} catch (e) {
		next({
			name: 'Home',
			// query: {redirectFrom: to.fullPath}
		})
	}
};


const routes = [
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: Home,
	// },
	{
		path: '/password-reset',
		name: 'PasswordReset',
		component: PasswordReset
	},
	{
		path: '/request-access',
		name: 'RequestAccess',
		component: RequestAccess
	},
	{
		path: '/showroom-visit',
		name: 'ShowroomVisit',
		component: ShowroomVisit
	},
	{
		path: '/pedrali-app',
		name: 'PedraliApp',
		component: PedraliApp
	},
	{
		path: '/disclaimer',
		name: 'Disclaimer',
		component: DisclaimerPage
	},
	{
		path: '/:id1?/:id2?/:id3?/:id4?/:id5?/:id6?/:id7?/:id8?/:id9?/',
		name: 'Home',
		component: Home,
		beforeEnter (to, from, next) {
			guardAuthService(to, from, next);
		}
	},
	// {
	// 	path: '/area-riservata/:id1?/:id2?/:id3?/:id4?/:id5?/',
	// 	name: 'AreaRiservata',
	// 	component: AreaRiservata,
	// 	beforeEnter (to, from, next) {
	// 		guardAuthService(to, from, next);
	// 	}
	// },

]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
